import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';
import { useAuthContext } from 'src/auth/hooks';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetMyActions() {
  const URL = `/action/teamactions`;

  const { data: actions, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      actions,
      isLoading,
      isEmpty: !isLoading && !actions?.length,
      error,
      isValidating,
      mutate,
    }),
    [actions, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetActionsByPlan(planId: number | null) {
  const URL = `/action/actionsbyactionplan?actionPlanId=${planId}`;

  const { data: actions, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      actions,
      isLoading,
      isEmpty: !isLoading && !actions?.length,
      error,
      isValidating,
      mutate,
    }),
    [actions, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetAction(id: string | null) {
  const URL = id ? `/action/${id}` : null;

  const { data: action, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      action,
      isLoading,
      notFound: !isLoading && !action,
      error,
      isValidating,
      mutate,
    }),
    [action, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetActionComments(actionId: number, offset = 0, count = 10) {
  const URL = `/actioncomments?actionId=${actionId}&offset=${offset}&count=${count}`;

  const { data: comments, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      comments,
      isLoading,
      isEmpty: !isLoading && !comments?.length,
      error,
      isValidating,
      mutate,
    }),
    [comments, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function createAction(data: any) {
  const res = await axiosInstance.post(`/actiongroup`, data);
  return res.data;
}

export async function updateAction(id: number, data: any) {
  const res = await axiosInstance.patch(`/action/${id}`, data);
  return res.data;
}

export async function updateActionStatus(id: number, data: any) {
  const res = await axiosInstance.patch(`/actionstatus/${id}`, data);
  return res.data;
}

export async function addComment(data: any) {
  const res = await axiosInstance.post(`/actioncomment`, data);
  return res.data;
}

export async function updateComment(id: number, data: any) {
  const res = await axiosInstance.patch(`/actioncomment/${id}`, data);
  return res.data;
}

export async function deleteComment(id: number) {
  const res = await axiosInstance.delete(`/actioncomment/${id}`);
  return res.data;
}
