import * as auth from './auth';
import * as role from './role';
import * as data from './data';
import * as need from './need';
import * as group from './group';
import * as topic from './topic';
import * as survey from './survey';
import * as action from './action';
import * as segment from './segment';
import * as setting from './setting';
import * as mailing from './mailing';
import * as heatmap from './heatmap';
import * as employee from './employee';
import * as question from './question';
import * as feedback from './feedback';
import * as dashboard from './dashboard';
import * as elearning from './elearning';
import * as actionPlan from './action-plan';
import * as notification from './notification';
import * as actionLibrary from './action-library';
import * as customHeatmap from './custom-heatmap';
import * as segmentSetting from './segment-setting';
import * as surveyDashboard from './survey-dashboard';
import * as driverDashboard from './driver-dashboard';
import * as subsidiariesDashboard from './subsidiaries-dashboard';
import * as customSurveyDashboard from './custom-survey-dashboard';

const api = {
  auth,
  employee,
  role,
  group,
  segmentSetting,
  setting,
  data,
  mailing,
  survey,
  topic,
  question,
  notification,
  actionLibrary,
  action,
  need,
  surveyDashboard,
  segment,
  heatmap,
  feedback,
  driverDashboard,
  customSurveyDashboard,
  customHeatmap,
  dashboard,
  elearning,
  actionPlan,
  subsidiariesDashboard,
};

export default api;
