import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

import { IGroup } from 'src/types/group';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetGroupTypes() {
  const URL = `/grouptypes`;

  const {
    data: groupTypes,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      groupTypes,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [groupTypes, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetGroup(id: number) {
  const URL = `/groups/${id}`;

  const {
    data: group,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR<IGroup>(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      group,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [group, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetGroups(id?: number) {
  let URL = `/groups`;
  if (id) {
    URL += `?parentGroupId=${id}`;
  }

  const { data, isLoading, error } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      error,
    }),
    [data, error, isLoading]
  );

  return memoizedValue;
}

export async function createGroup(data: any) {
  const res = await axiosInstance.post('/groups', data);
  return res.data;
}

export async function updateGroup(id: number, data: any) {
  const res = await axiosInstance.patch(`/groups/${id}`, data);
  return res.data;
}

export async function deleteGroup(id: number) {
  const res = await axiosInstance.delete(`/groups/${id}`);
  return res.data;
}

export async function createGroupType(data: any) {
  const res = await axiosInstance.post('/grouptype', data);
  return res.data;
}

export async function importGroupTypes(data: any) {
  const res = await axiosInstance.post('/import/importgrouptypes', data);
  return res.data;
}

export async function importGroups(data: any) {
  const res = await axiosInstance.post('/import/importgroups', data);
  return res.data;
}

export async function importDeleteGroups(data: object) {
  const res = await axiosInstance.post('/import/importdeletegroups', data);
  return res.data;
}
