import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

import { objectToQueryString } from 'src/helpers/survey-dashboard';

// -------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetFeedbacks(
  planningId: number | null,
  roundId: number | null,
  segments_query: any,
  scope: string,
  page: number = 0,
  itemsPerPage: number = 10,
  include_children: boolean = true,
  filters: any = {}
) {
  let URL = null;

  if (planningId && roundId) {
    URL = `/feedback/feedbackbysegment?planning_id=${planningId}&round_id=${roundId}&${objectToQueryString(
      segments_query
    )}&scope=${scope}&include_children=${include_children}&page=${
      page + 1
    }&itemsPerPage=${itemsPerPage}&${objectToQueryString(filters)}`;
  }
  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      feedbacks: data?.rows,
      count: data?.count,
      anonymat: data?.anonymat,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetFeedbackByRound(roundId: number | null, scope: string, limit: number = 3) {
  let URL = null;

  if (roundId) {
    URL = `/feedback/feedbackbysurvey?roundId=${roundId}&scope=${scope}&itemsPerPage=${limit}`;
  }

  const { data: feedbacks, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      feedbacks,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [feedbacks, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetFeedbackBySegment(
  planningId: number | null,
  roundId: number | null,
  segments_query: any,
  scope: string,
  page: number = 1,
  itemsPerPage: number = 10,
  include_children: boolean = true,
  filters: any = {}
) {
  let URL = null;

  if (planningId) {
    URL = `/feedback/feedbackbysegment?planning_id=${planningId}&round_id=${roundId}&${objectToQueryString(
      segments_query
    )}&scope=${scope}&include_children=${include_children}&page=${page}&itemsPerPage=${itemsPerPage}&${objectToQueryString(
      filters
    )}`;
  }

  const { data: feedbacks, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      feedbacks,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [feedbacks, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetSurveyQuestions(planningId: number) {
  // N.Z 04 June 21  ENABLE getting all custom Questions Pulses
  const URL = `/planningquestions?planningId=${planningId}`;

  const { data: questions, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      questions,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [questions, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetSentimentStats(
  planningId: number | null,
  roundId: number | null,
  segments_query: any,
  scope: string,
  include_children: boolean = true,
  filters: any = {}
) {
  let URL = null;

  if (planningId) {
    // if (bySegment) {
    URL = `/feedback/sentimentsStatsBySegment?planningId=${planningId}&roundId=${roundId}&${objectToQueryString(
      segments_query
    )}&scope=${scope}&include_children=${include_children}&${objectToQueryString(filters)}`;
    // } else {
    //   URL = `/feedback/sentimentsStatsBySurvey?planningId=${planningId}&${objectToQueryString(
    //     segments_query
    //   )}&scope=${scope}&include_children=${include_children}`;
    // }
  }
  const {
    data: sentiments,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      sentiments,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [sentiments, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetKeywordsStats(
  planningId: number | null,
  segments_query: any,
  include_children: boolean = true
) {
  const URL = `/feedback/keywordsStats?planning_id=${planningId}&${objectToQueryString(
    segments_query
  )}&include_children=${include_children}`;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      keywords: data?.keywords?.data,
      count: data?.keywords?.count,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetTopicsStats(
  planningId: number | null,
  segments_query: any,
  include_children: boolean = true
) {
  const URL = `/feedback/topicsStats?planning_id=${planningId}&${objectToQueryString(
    segments_query
  )}&include_children=${include_children}`;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      topics: data?.topics?.data,
      count: data?.topics?.count,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function getAllFeedbacks(
  roundId: number | null,
  segments_query: any,
  scope: string,
  page: number = 0,
  itemsPerPage: number = 10,
  include_children: boolean = true,
  filters: any = {}
) {
  let URL = null;

  if (roundId) {
    URL = `/feedback/feedbackbysurvey?roundId=${roundId}&${objectToQueryString(
      segments_query
    )}&scope=${scope}&include_children=${include_children}&page=${
      page + 1
    }&itemsPerPage=${itemsPerPage}&${objectToQueryString(filters)}`;
  }
  if (!URL) return null;

  const res = await axiosInstance.get(URL);
  return res.data;
}

export async function getFeedbackChat(id: number, code?: string) {
  let URL = '/feedbackchat';
  if (id) {
    URL += `?surveyResultId=${id}`;
  } else {
    URL += `?email_code=${code}`;
  }

  const res = await axiosInstance.get(URL);
  return res.data;
}

export async function getFeedbackChatExternal(code: string) {
  const res = await axiosInstance.get(`/feedbackchat/external?email_code=${code}`);
  return res.data;
}

export async function submitFeedbackChat(data: any) {
  const res = await axiosInstance.post('/feedbackchat', data);
  return res.data;
}

export async function submitFeedbackChatExternal(data: any) {
  const res = await axiosInstance.post('/feedbackchat/external', data);
  return res.data;
}

export async function updateMessageStatus(data: any) {
  const res = await axiosInstance.post('/feedback/updatemessagestatus', data);
  return res.data;
}
