export const paths = {
  root: '/',
  surveyDashboard: {
    root: `/survey-dashboard`,
    analysis: `/survey-dashboard/analysis`,
    heatmap: `/survey-dashboard/heatmap`,
    feedback: `/survey-dashboard/feedback`,
    segments: `/survey-dashboard/segments`,
    segmentDashboard: `/survey-dashboard/segment-dashboard`,
    driverDashboard: `/survey-dashboard/driver-dashboard`,
  },
  customSurveyDashboard: {
    root: `/custom-survey-dashboard`,
    heatmap: `/custom-survey-dashboard/heatmap`,
    feedback: `/custom-survey-dashboard/feedback`,
    segments: `/custom-survey-dashboard/segments`,
    segmentDashboard: `/custom-survey-dashboard/segment-dashboard`,
  },
  schedules: {
    root: `/schedules`,
    list: `/schedules`,
    new: `/schedules/new`,
  },
  actions: {
    root: `/actions`,
    actionPlan: `/actions/action-plan`,
    library: `/actions/library`,
    myActions: `/actions/my-actions`,
    new: `/actions/new`,
    edit: (id: string) => `/actions/edit/${id}`,
  },
  needs: {
    root: `/needs`,
    list: `/needs`,
    edit: `/needs/edit`,
  },
  // ADLINISTRATION
  company: {
    root: `/company`,
    employees: {
      root: `/company/employees`,
      new: `/company/employees/new`,
      edit: (id: string) => `/company/employees/edit/${id}`,
    },
    roles: {
      root: `/company/roles`,
    },
    groups: {
      root: `/company/groups`,
    },
    segments: {
      root: `/company/segments`,
    },
    security: {
      root: `/company/security`,
    },
  },
  // SURVEY MANAGEMENT
  survey: {
    root: `/survey`,
    data: {
      root: `/survey/data`,
    },
    mailing: {
      root: `/survey/mailing`,
    },
    topics: {
      root: `/survey/topics`,
      new: `/survey/topics/new`,
      edit: (id: number) => `/survey/topics/edit/${id}`,
    },
  },
  elearning: {
    root: `/elearning`,
  },
  subsidiaries: {
    dashboard: '/subsidiaries-dashboard',
  },
  // AUTH
  auth: {
    jwt: {
      login: `/login`,
      forgotPassword: `/auth/forgot-password`,
      reset: `/auth/reset`,
    },
  },
  notifications: '/notifications',
  notFound: '/404',
};
