import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import {
  Box,
  Card,
  Stack,
  InputBase,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';

import api from 'src/api';
import { useTranslate } from 'src/locales';
import { PageNotFoundIllustration } from 'src/assets/illustrations';

import Iconify from 'src/components/iconify/iconify';
import { useSettingsContext } from 'src/components/settings';

import ChatMessageList from '../survey-dashboard/feedback/components/feedbacks-tab/chat-message-list';

export default function ConversationsView() {
  const settings = useSettingsContext();
  const { t } = useTranslate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [notfound, setNotfound] = useState(false);

  const [newMessage, setNewMessage] = useState('');
  const [sendMessageLoading, setSendMessageLoading] = useState(false);

  const [surveyName, setSurveyName] = useState('');

  const [question, setQuestion] = useState('');
  const [originalFeedback, setOriginalFeedback] = useState<any>(null);
  const [feedbackDate, setFeedbackDate] = useState<any>(null);

  const [feedbackMessages, setFeedbackMessages] = useState([]);
  const [chatParticipants, setChatParticipants] = useState<any[]>([]);

  useEffect(() => {
    getFeedbackChatData();
  }, []);

  const getFeedbackChatData = async () => {
    try {
      setLoading(true);

      const code = searchParams.get('code') || null;

      if (!code) {
        throw new Error('Invalid code');
      }

      const chatMessages = await api.feedback.getFeedbackChatExternal(encodeURIComponent(code));

      if (!chatMessages.length) {
        throw new Error('No chat messages found');
      }

      const surveyResult = chatMessages[0]?.survey_result;

      if (!surveyResult) {
        throw new Error('No survey result found');
      }

      setOriginalFeedback(chatMessages[0]);

      const chatMessagesMapped = chatMessages.map((chat: any) => ({
        id: chat.id,
        body: chat.message,
        createdAt: chat.createdAt,
        me: chat.external,
        external: chat.external,
        employeeId: chat.external ? chat?.employeeCypher : chat.employeeId,
        employeeName: chat.external
          ? t('feedback_chat.anonymous')
          : `${chat.employee.firstName} ${chat.employee.lastName}`,
        contentType: 'text',
      }));

      chatMessagesMapped.unshift({
        id: surveyResult.id,
        body: surveyResult.feedback,
        createdAt: surveyResult.createdAt,
        me: true,
        external: true,
        employeeId: surveyResult?.employee_demographic?.employeeCypher,
        employeeName: t('feedback_chat.anonymous'),
        contentType: 'text',
      });

      setFeedbackMessages(chatMessagesMapped);

      // get unique participants from chat messages
      const _chatParticipants = chatMessages.map((chat: any) => ({
        id: chat.employeeId,
        name: `${chat.employee.firstName} ${chat.employee.lastName}`,
        avatarUrl: null,
      })) as any[];

      // remove duplicates
      const uniqueParticipants = _chatParticipants.filter(
        (v, i, a) => a.findIndex((y) => y.id === v.id) === i
      );

      uniqueParticipants.unshift({
        id: surveyResult?.employee_demographic?.employeeCypher,
        name: t('feedback_chat.anonymous'),
        avatarUrl: null,
      });

      setChatParticipants(uniqueParticipants);

      if (surveyResult?.pulse_question) {
        setQuestion(surveyResult.pulse_question.pulse_questions_trans[0].description);
      } else if (surveyResult?.custom_pulse_question) {
        setQuestion(surveyResult.custom_pulse_question.description);
      } else if (surveyResult?.open_question) {
        setQuestion(surveyResult.open_question.open_questions_trans[0].description);
      } else if (surveyResult?.custom_open_question) {
        setQuestion(surveyResult.custom_open_question.description);
      }

      if (surveyResult?.createdAt) {
        setFeedbackDate(format(new Date(surveyResult?.createdAt), 'dd/MM/yyyy HH:mm'));
      }
      setSurveyName(surveyResult?.planning?.name);
    } catch (error) {
      console.error(error);
      setNotfound(true);
      enqueueSnackbar('error getting chat data', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const onSendMessage = async () => {
    try {
      setSendMessageLoading(true);

      if (newMessage.length > 500) {
        enqueueSnackbar(t('feedback_chat.max_message_length'), { variant: 'error' });
        return;
      }

      await api.feedback.submitFeedbackChatExternal({
        createdAt: new Date(),
        updatedAt: new Date(),
        message: newMessage,
        external: 1,
        surveyResultId: originalFeedback.surveyResultId,
        employeeId: originalFeedback?.employeeId,
        employeeCypher: originalFeedback?.employeeCypher,
        employeeLocale: originalFeedback.employee.locale,
        surveyName,
      });

      setNewMessage('');
      getFeedbackChatData();

      enqueueSnackbar(t('feedback_chat.message_sent'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(t('alerts.create_failed'), { variant: 'error' });
      console.error(err);
    } finally {
      setSendMessageLoading(false);
    }
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Box p={3}>
        <Box textAlign="center" mb={4}>
          <Typography variant="h4" sx={{ mb: 1, textTransform: 'uppercase' }}>
            <Iconify
              icon="material-symbols:lock"
              width={28}
              height={28}
              sx={{ mr: 0.5, mb: -0.5 }}
            />
            {t('conversations.anonymous_conversation')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('conversations.anonymous_conversation_desc')}
          </Typography>
        </Box>

        {
          // loading
          loading && (
            <Stack direction="row" justifyContent="center" alignItems="center" mt={12}>
              <CircularProgress />
            </Stack>
          )
        }

        {
          // not found
          !loading && notfound && (
            <PageNotFoundIllustration
              sx={{
                height: 220,
                my: { xs: 3, sm: 6 },
              }}
            />
          )
        }

        {!loading && !notfound && (
          <Box mb={3}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              {t('conversations.your_answer1', { survey: surveyName })}
            </Typography>

            <Card sx={{ p: 2, mb: 3 }}>
              <Typography
                variant="subtitle2"
                sx={{ pb: 1, mb: 1, borderBottom: '1px solid #e0e0e0' }}
              >
                {question}
              </Typography>
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                  }}
                >
                  {`${t('conversations.your_answer2')} (${feedbackDate})`}
                </Typography>
                <Typography variant="body2">{originalFeedback?.survey_result?.feedback}</Typography>
              </Box>
            </Card>

            <Card>
              <Stack
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'white',
                  p: 2,
                }}
              >
                <Typography variant="subtitle1">
                  {t('conversations.anonymous_conversation')}
                </Typography>
                <Typography variant="caption">
                  {chatParticipants.map((p) => p?.name).join(', ')}
                </Typography>
              </Stack>

              <Stack
                sx={{
                  width: 1,
                  height: 550,
                  overflow: 'hidden',
                }}
              >
                <ChatMessageList messages={feedbackMessages} participants={chatParticipants} />
                <InputBase
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder={t('feedback_chat.enter_message')}
                  endAdornment={
                    <IconButton color="primary" onClick={onSendMessage}>
                      {sendMessageLoading ? (
                        <CircularProgress size={18} sx={{ mr: 0.5 }} />
                      ) : (
                        <Iconify icon="material-symbols:send" />
                      )}
                    </IconButton>
                  }
                  sx={{
                    pl: 2,
                    pr: 1,
                    height: 56,
                    flexShrink: 0,
                    borderTop: (theme: any) => `solid 1px ${theme.palette.divider}`,
                  }}
                />
              </Stack>
            </Card>
          </Box>
        )}
      </Box>
    </Container>
  );
}
