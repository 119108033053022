import { format } from 'date-fns';
import { toLower } from 'lodash';

import i18n from 'src/locales/i18n';
import { error, grey, info, success, warning } from 'src/theme/palette';

export function getStatusLabel(status: string) {
  return (status && i18n.t(`my_actions_page.status.${toLower(status)}`)) || '';
}

export function getStatusColor(
  status: string
): 'default' | 'error' | 'warning' | 'success' | 'info' | 'primary' | 'secondary' {
  switch (status) {
    case 'PENDING':
      return 'default';
    case 'ONGOING':
      return 'warning';
    case 'DEPLOYED':
      return 'success';
    case 'CLOSED':
      return 'info';
    case 'CANCELLED':
      return 'error';
    default:
      return 'default';
  }
}

export function getStatusDate(action: any, dateFormat: string = 'dd/MM/yyyy') {
  switch (action.status) {
    case 'PENDING':
      return format(new Date(action.createdAt), dateFormat);
    case 'ONGOING':
      return format(new Date(action.ongoingAt), dateFormat);
    case 'DEPLOYED':
      return format(new Date(action.deployedAt), dateFormat);
    case 'CLOSED':
      return format(new Date(action.completed), dateFormat);
    case 'CANCELLED':
      return format(new Date(action.updatedAt), dateFormat);
    default:
      return '';
  }
}

export function getStatusProgress(status: string) {
  switch (status) {
    case 'PENDING':
      return 0;
    case 'ONGOING':
      return 33;
    case 'DEPLOYED':
      return 66;
    case 'CLOSED':
      return 100;
    case 'CANCELLED':
      return 0;
    default:
      return 0;
  }
}

export function groupActionsByDriver(actions: any[]) {
  return actions?.reduce(
    (r, item) => {
      try {
        let category = '';
        let subdriver = '';
        let standard = false;
        if (item.pulseQuestionCategoryId) {
          category = item.pulse_question_category.pulse_question_categories_trans[0].name;
          standard = true;
        } else if (item.customPulseQuestionCategoryId) {
          category = item.custom_pulse_question_category.name;
        } else if (item.pulseQuestionSubcatId) {
          category =
            item.pulse_question_subcat.pulse_question_category.pulse_question_categories_trans[0]
              .name;
          subdriver = item.pulse_question_subcat.pulse_question_subcats_trans[0].name;
          standard = true;
        } else if (item.custom_pulse_question_subcat.pulse_question_category) {
          category =
            item.custom_pulse_question_subcat.pulse_question_category
              ?.pulse_question_categories_trans[0]?.name;
          subdriver = item.custom_pulse_question_subcat?.name;
          standard = true;
        } else {
          category = item.custom_pulse_question_subcat?.custom_pulse_question_category?.name;
          subdriver = item.custom_pulse_question_subcat?.name;
        }

        let current = r.hash[category];

        if (!current) {
          r.hash[category] = {
            category,
            standard,
            items: [],
          };

          current = r.hash[category];

          r.arr.push(current);
        }

        current.items.push({
          id: item.id,
          action: item.action,
          action_plan_id: item.actionPlanId,
          action_plan: item.action_plan,
          action_groups: item.action_groups,
          description: item.description,
          notes: item.notes,
          risks: item.risks,
          completed: item.completed,
          due: item.due,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
          deployedAt: item.deployedAt,
          ongoingAt: item.ongoingAt,
          public: item.public,
          employee: item.employee,
          creator_lastName: item.employee.lastName,
          creator_firstName: item.employee.firstName,
          creator_avatar: item.employee.avatar,
          creator_id: item.employee.id,
          owner: item.owner,
          owner_lastName: item.owner.lastName,
          owner_firstName: item.owner.firstName,
          owner_avatar: item.owner.avatar,
          owner_id: item.owner.id,
          owner_group_id: item.owner?.company_group ? item.owner.company_group.id : null,
          owner_group_name: item.owner?.company_group?.name || '',
          target_groups: item.action_groups
            .map((group: any) => group.company_group && group.company_group.name)
            .join(', '),
          avatar: item.employee?.avatar,
          status: item.status,
          subdriver,
          pulseQuestionCategoryId: item.pulseQuestionCategoryId,
          customPulseQuestionCategoryId: item.customPulseQuestionCategoryId,
          pulseQuestionSubcatId: item.pulseQuestionSubcatId,
          customPulseQuestionSubcatId: item.customPulseQuestionSubcatId,
        });

        return r;
      } catch (err) {
        console.error(`err:${err.message}`);
        return null;
      }
    },
    { hash: {}, arr: [] }
  ).arr;
}

export function getActionDriver(action: any): { id: number; type: 'STANDARD' | 'CUSTOM' } | null {
  if (action?.pulse_question_category) {
    return {
      id: action.pulse_question_category.id,
      type: 'STANDARD',
    };
  }
  if (action?.custom_pulse_question_category) {
    return {
      id: action.custom_pulse_question_category.id,
      type: 'CUSTOM',
    };
  }

  if (action?.pulse_question_subcat) {
    return {
      id: action.pulse_question_subcat.pulse_question_category?.id,
      type: 'STANDARD',
    };
  }

  if (action?.custom_pulse_question_subcat) {
    if (action?.custom_pulse_question_subcat?.pulse_question_category) {
      return {
        id: action?.custom_pulse_question_subcat?.pulse_question_category?.id,
        type: 'STANDARD',
      };
    }

    if (action?.custom_pulse_question_subcat?.custom_pulse_question_category) {
      return {
        id: action.custom_pulse_question_subcat.custom_pulse_question_category.id,
        type: 'CUSTOM',
      };
    }
  }

  return null;
}

export function getActionSubdriver(
  action: any
): { id: number; type: 'STANDARD' | 'CUSTOM' } | null {
  if (action?.pulse_question_subcat) {
    return {
      id: action.pulse_question_subcat.id,
      type: 'STANDARD',
    };
  }

  if (action?.custom_pulse_question_subcat) {
    return {
      id: action.custom_pulse_question_subcat.id,
      type: 'CUSTOM',
    };
  }

  return null;
}
