import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import useHasPermission from 'src/hooks/use-has-permission';

import api from 'src/api';
import { paper } from 'src/theme/css';
import { useAuthContext } from 'src/auth/hooks';
import { getCompanyLogo } from 'src/helpers/company';
import { setCompanyLogoChanged } from 'src/redux/slices/common';

import { UploadAvatar } from 'src/components/upload';
import FormProvider from 'src/components/hook-form/form-provider';
import RHFColorPicker from 'src/components/hook-form/rhf-color-picker';

import Iconify from '../../iconify';
import Scrollbar from '../../scrollbar';
import BaseOptions from './base-option';
import StretchOptions from './stretch-options';
import { useSettingsContext } from '../context';
import FullScreenOption from './fullscreen-option';

// ----------------------------------------------------------------------

export default function SettingsDrawer() {
  const theme = useTheme();

  const settings = useSettingsContext();

  const labelStyles = {
    mb: 1.5,
    color: 'text.disabled',
    fontWeight: 'fontWeightSemiBold',
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const hasWritePermission = useHasPermission('branding', 'W');

  const { user } = useAuthContext();

  const [logoPreview, setLogoPreview] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (user) {
      setLogoPreview(getCompanyLogo(user?.companyId));
    }
  }, [user]);

  const ChangePassWordSchema = Yup.object().shape({
    toolbar_color: Yup.string().required('Old Password is required'),
  });

  const defaultValues = useMemo(
    () => ({
      toolbar_color: settings.themeColor,
    }),
    [settings.themeColor]
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!isDirty) {
        return;
      }

      await api.setting.updateCompanySettings(data);

      reset(data);
      settings.onUpdateThemeColor(data.toolbar_color);
      enqueueSnackbar(t('alerts.update_success'), { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('alerts.update_failed'), { variant: 'error' });
    }
  });

  const handleDrop = async (acceptedFiles: File[]) => {
    try {
      const file = acceptedFiles[0];

      const formData = new FormData();

      const extension = file.name.split('.');
      const fileName = `${user?.companyId}.${extension[1]}`;

      formData.append('logo', file, fileName);
      formData.append('type', 'internal');

      await api.setting.uploadCompanyLogo(formData);

      setLogoPreview(`${getCompanyLogo(user?.companyId)}?=${Date.now()}`);
      dispatch(setCompanyLogoChanged(true));

      enqueueSnackbar(t('alerts.update_success'), { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('alerts.update_failed'), { variant: 'error' });
    }
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {t('menu.admin.company.settings')}
      </Typography>

      <IconButton onClick={settings.onClose}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  // const renderMode = (
  //   <div>
  //     <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
  //       Mode
  //     </Typography>

  //     <BaseOptions
  //       value={settings.themeMode}
  //       onChange={(newValue: string) => settings.onUpdate('themeMode', newValue)}
  //       options={['light', 'dark']}
  //       icons={['sun', 'moon']}
  //     />
  //   </div>
  // );

  // const renderContrast = (
  //   <div>
  //     <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
  //       Contrast
  //     </Typography>

  //     <BaseOptions
  //       value={settings.themeContrast}
  //       onChange={(newValue: string) => settings.onUpdate('themeContrast', newValue)}
  //       options={['default', 'bold']}
  //       icons={['contrast', 'contrast_bold']}
  //     />
  //   </div>
  // );

  // const renderDirection = (
  //   <div>
  //     <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
  //       Direction
  //     </Typography>

  //     <BaseOptions
  //       value={settings.themeDirection}
  //       onChange={(newValue: string) => settings.onUpdate('themeDirection', newValue)}
  //       options={['ltr', 'rtl']}
  //       icons={['align_left', 'align_right']}
  //     />
  //   </div>
  // );

  // const renderStretch = (
  //   <div>
  //     <Typography
  //       variant="caption"
  //       component="div"
  //       sx={{
  //         ...labelStyles,
  //         display: 'inline-flex',
  //         alignItems: 'center',
  //       }}
  //     >
  //       Stretch
  //       <Tooltip title="Only available at large resolutions > 1600px (xl)">
  //         <Iconify icon="eva:info-outline" width={16} sx={{ ml: 0.5 }} />
  //       </Tooltip>
  //     </Typography>

  //     <StretchOptions
  //       value={settings.themeStretch}
  //       onChange={() => settings.onUpdate('themeStretch', !settings.themeStretch)}
  //     />
  //   </div>
  // );

  return (
    <Drawer
      anchor="right"
      open={settings.open}
      onClose={settings.onClose}
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          width: 'auto',
        },
      }}
    >
      {renderHead}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box p={3}>
          <Stack direction="row" spacing={3} mb={3}>
            <Box
              sx={{
                width: { xs: 150, sm: 300 },
                maxWidth: { xs: 150, sm: 300 },
              }}
            >
              <Typography variant="subtitle1" mb={0.5}>
                {t('branding_page.main_logo')} :
              </Typography>
              <Typography variant="body2" fontSize={12} sx={{ color: 'text.secondary' }}>
                {t('branding_page.main_logo_desc')} ({t('branding_page.see')}{' '}
                <a>{t('branding_page.mail_templates')}</a>)
              </Typography>
            </Box>

            <Box>
              <Stack direction="row" justifyContent="end">
                <UploadAvatar
                  file={logoPreview}
                  maxSize={3145728}
                  onDrop={handleDrop}
                  sx={{ height: 110, width: '100%' }}
                  borderRadius="8px"
                />
              </Stack>
            </Box>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Box
              sx={{
                width: { xs: 150, sm: 300 },
                maxWidth: { xs: 150, sm: 300 },
              }}
            >
              <Box>
                <Typography variant="subtitle1" mb={0.5}>
                  {t('branding_page.theme_color')} :
                </Typography>
                <Typography variant="body2" fontSize={12} sx={{ color: 'text.secondary' }}>
                  {t('branding_page.theme_color_desc')}.
                </Typography>
              </Box>
            </Box>

            <Box>
              <Stack direction="row" justifyContent="end">
                <Stack direction="row" width={150}>
                  <RHFColorPicker name="toolbar_color" onClose={onSubmit} height={54} />
                </Stack>
              </Stack>
            </Box>

            {/* <Grid item xs={12} mt={1}>
                <Stack direction="row" justifyContent="end" gap={2} mt={4}>
                  <Button variant="outlined" onClick={() => reset()} disabled={!hasWritePermission}>
                    {t('common.cancel')}
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!hasWritePermission}
                  >
                    {t('common.save')}
                  </LoadingButton>
                </Stack>
              </Grid> */}
          </Stack>
        </Box>
      </FormProvider>

      {/* <Stack spacing={3} sx={{ p: 3 }}>
          {renderMode}

          {renderContrast}

          {renderDirection}

          {renderStretch}
        </Stack> */}

      {/* <FullScreenOption /> */}
    </Drawer>
  );
}
