export function groupRolePermissions(items: any[]) {
  return items.reduce(
    (r, item) => {
      try {
        let current = r.hash[item.role.roles_trans[0].name];

        if (!current) {
          r.hash[item.role.roles_trans[0].name] = {
            role: item.role.roles_trans[0].name,
            permissions: [],
          };

          current = r.hash[item.role.roles_trans[0].name];

          r.arr.push(current);
        }

        current.permissions.push({
          id: item.id,
          permissionId: item.permissionId,
          parentPermissionId: item.permission.parentPermissionId,
          name: item.permission.permissions_trans[0].name,
          group: item.permission.permissions_trans[0].group,
          description: item.permission.permissions_trans[0].description,
          active: true,
        });

        return r;
      } catch (err) {
        console.error(`err:${err.message}`);
        return null;
      }
    },
    { hash: {}, arr: [] }
  ).arr;
}

export function groupPermissions(items: any[]) {
  return items.reduce(
    (r, item) => {
      try {
        let current = r.hash[item.group];

        if (!current) {
          r.hash[item.group] = {
            group: item.group,
            items: [],
          };

          current = r.hash[item.group];

          r.arr.push(current);
        }

        current.items.push({
          id: item.id,
          permissionId: item.permissionId,
          parentPermissionId: item.parentPermissionId,
          group: item.group,
          name: item.name,
          description: item.description,
          active: item.active,
        });

        return r;
      } catch (err) {
        console.error(`err:${err.message}`);
        return null;
      }
    },
    { hash: {}, arr: [] }
  ).arr;
}
