import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));
const NewPasswordPage = lazy(() => import('src/pages/auth/jwt/new-password'));

export const authRoutes = [
  {
    path: '',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        element: (
          <AuthModernCompactLayout>
            <Outlet />
          </AuthModernCompactLayout>
        ),
        children: [
          { path: '/login', element: <JwtLoginPage /> },
          { path: '/auth/forgot-password', element: <ForgotPasswordPage /> },
          { path: '/auth/reset', element: <NewPasswordPage /> },
        ],
      },
    ],
  },
];
