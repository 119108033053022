import { Box, Link, Typography } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { APP_VERSION } from 'src/constants/common';

import { useTranslate } from 'src/locales';

export default function Footer() {
  const { t } = useTranslate();

  const { user } = useAuthContext();

  function getAppVersion() {
    const environment = process.env.NODE_ENV;
    const serverVersion = user?.version;

    return `${environment} version: ${APP_VERSION} | server version: ${serverVersion}`;
  }

  return (
    <Box
      mt={4}
      p={2}
      textAlign="center"
      // sx={{ borderTop: (theme) => `solid 1px ${theme.palette.divider}` }}
    >
      <Typography variant="caption">
        <Typography component="span" variant="caption" mr={1}>
          <Link href="http://www.positeams.com">Positeams.com</Link> &copy;{' '}
          {new Date().getFullYear()}
        </Typography>
        <Typography component="span" variant="caption" mr={1}>
          {getAppVersion()}
        </Typography>
        <Typography component="span" variant="caption">
          <Link href="http://www.positeams.com/cgu.html">CGU</Link>
        </Typography>
      </Typography>
    </Box>
  );
}
