import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetTopic(id?: number | null) {
  const URL = id ? `/surveytypes/${id}` : null;

  const { data: topic, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      topic,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [topic, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetTopics() {
  const URL = `/surveytypes`;

  const { data: topics, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      topics,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [topics, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetPyramidOfNeeds() {
  const URL = `/pyramidofneeds`;

  const {
    data: pyramidOfNeeds,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      pyramidOfNeeds,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [pyramidOfNeeds, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function createTopic(data: any) {
  const res = await axiosInstance.post(`/surveytype`, data);
  return res.data;
}

export async function updateTopic(id: number, data: any) {
  const res = await axiosInstance.patch(`/surveytype/${id}`, data);
  return res.data;
}

export async function deleteTopic(id: number) {
  const res = await axiosInstance.delete(`/surveytype/${id}`);
  return res.data;
}

export async function updateCustomPulseQuestions(id: number, data: any) {
  const res = await axiosInstance.patch(`/custompulsequestion/${id}`, data);
  return res.data;
}

export async function updateCustomOpenQuestions(id: number, data: any) {
  const res = await axiosInstance.patch(`/customopenquestion/${id}`, data);
  return res.data;
}

export async function deleteCustomPulseQuestions(id: number) {
  const res = await axiosInstance.delete(`/custompulsequestion/${id}`);
  return res.data;
}

export async function deleteCustomOpenQuestions(id: number) {
  const res = await axiosInstance.delete(`/customopenquestion/${id}`);
  return res.data;
}

export async function importQuestions(data: any) {
  const res = await axiosInstance.post('/import/importquestions', data);
  return res.data;
}
