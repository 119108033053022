import { useMemo } from 'react';
import { format, isValid } from 'date-fns';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Box, Select, MenuItem, Typography } from '@mui/material';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import useHasPermission from 'src/hooks/use-has-permission';

import { bgBlur } from 'src/theme/css';
import { useTranslate } from 'src/locales';
import { getPageTitle } from 'src/helpers/common';
import MenuItemSvg from 'src/assets/images/ic_menu_item.svg';
import { setRoundId } from 'src/redux/slices/survey-dashboard';

import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';

import { NAV, HEADER } from '../config-layout';
import SettingsButton from '../common/settings-button';
import AccountPopover from '../common/account-popover';
import NotificationsPopover from '../common/notifications-popover';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const { t } = useTranslate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const titleDescription = useMemo(() => {
    if (pathname === '/actions/new') {
      return state?.driverName || '';
    }

    return '';
  }, [pathname, state]);

  const {
    showSurveyMenu,
    survey: { roundId, history },
  } = useSelector((s: any) => s.surveyDashboard);

  const showHistory = useMemo(
    () => (showSurveyMenu || pathname === '/') && history?.length && roundId,
    [history, pathname, showSurveyMenu, roundId]
  );

  const settingPermission = useHasPermission('branding', 'R');

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const renderContent = (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width={1}>
      <Stack direction="row" alignItems="center">
        {!lgUp && (
          <IconButton onClick={onOpenNav} sx={{ mr: 2 }}>
            <SvgColor src={MenuItemSvg} />
          </IconButton>
        )}

        <Typography variant="h6">
          {getPageTitle(pathname)}

          {titleDescription && `: ${titleDescription}`}
        </Typography>
      </Stack>

      {showHistory ? (
        <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={15} sx={{ mr: 1 }}>
            {t('common.data_from')}:
          </Typography>
          <Select
            size="small"
            value={roundId}
            onChange={(e) => dispatch(setRoundId(e.target.value))}
            renderValue={(value) => (
              <Typography variant="body2">
                <Iconify
                  icon="material-symbols:timer"
                  width={20}
                  sx={{ color: 'primary.main', mb: -0.6, mr: 0.4 }}
                />
                {isValid(new Date(history?.find((item: any) => item.id === value)?.createdAt)) &&
                  format(
                    new Date(history?.find((item: any) => item.id === value)?.createdAt),
                    'dd MMMM yyyy'
                  )}
              </Typography>
            )}
            sx={{ height: 34 }}
          >
            {history
              ?.filter((item: any) => isValid(new Date(item.createdAt)))
              ?.sort(
                (a: any, b: any) =>
                  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
              )
              ?.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {format(new Date(item.createdAt), 'dd MMMM yyyy')}
                </MenuItem>
              ))}
          </Select>
        </Stack>
      ) : (
        <Box />
      )}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* <LanguagePopover /> */}

        <NotificationsPopover />

        {settingPermission && <SettingsButton />}

        <AccountPopover />
      </Stack>
    </Stack>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
            backgroundColor: 'background.neutral',
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 3 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
