export const FREQUENCIES = {
  oneTime: 6,
  weekly: 1,
  bimonthly: 4,
  monthly: 2,
  quarterly: 3,
  biannualy: 5,
};

export const ENGAGEMENT_TOPIC_ID = 1;
export const ENGAGEMENT_DRIVER_ID = 1;
