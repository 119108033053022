import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetNotifications(
  employeeId: number,
  count: number | null,
  new_notifications: boolean
) {
  const URL = `/usernotifications?employeeId=${employeeId}&sort=-createdAt${
    count !== null ? `&count=${count}` : ``
  }${new_notifications ? `&scope=new_notifications` : ``}`;

  const {
    data: notifications,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      notifications,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [notifications, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function markAsRead(id: number) {
  const res = await axiosInstance.post(`/notification/mark-as-read/${id}`);
  return res.data;
}

export async function clearNotifications() {
  const res = await axiosInstance.post('/notification/clear-all');
  return res.data;
}
