import { Outlet } from 'react-router';
import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
import ConversationsPage from 'src/pages/conversations';

import { SplashScreen, LoadingScreen } from 'src/components/loading-screen';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // Dashboard routes
    ...dashboardRoutes,

    // Auth routes
    ...authRoutes,
    {
      element: (
        <SimpleLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </SimpleLayout>
      ),
      children: [{ path: '/conversations', element: <ConversationsPage /> }],
    },
    {
      element: (
        <CompactLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </CompactLayout>
      ),
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
