import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';
import { useSelector } from 'react-redux';
import { alphaSort } from 'src/utils/format-array';
import { groupPermissions, groupRolePermissions } from 'src/helpers/role';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetRoles() {
  const URL = `/roles?sort=-is_default`;

  const { data: roles, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      roles,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [roles, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetRoleMembers() {
  const page = useSelector((state: any) => state.role?.page) || 0;
  const rowsPerPage = useSelector((state: any) => state.role?.rowsPerPage) || 5;
  const roleId = useSelector((state: any) => state.role?.roleId) || 0;
  const search = useSelector((state: any) => state.employee.search) || '';

  const offset = page * rowsPerPage;

  let URL = `/employeedirectory?count=${rowsPerPage}&offset=${offset}&sort=lastName&roleId=${roleId}`;

  if (search && search.length) URL += `&search=${search}`;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      members: data?.rows || [],
      count: data?.count || 0,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetGroups(rootGroupId: number) {
  const URL = `/groups`;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(() => {
    const groups = [] as any[];

    if (data) {
      data.forEach((group: any) => {
        const tmp = [];
        if (group.parentGroup && group.parentGroup.id !== rootGroupId) {
          tmp.push(group.parentGroup.name);
          if (group.parentGroup.parentGroup && group.parentGroup.parentGroup.id !== rootGroupId) {
            tmp.push(group.parentGroup.parentGroup.name);
            if (
              group.parentGroup.parentGroup.parentGroup &&
              group.parentGroup.parentGroup.parentGroup.id !== rootGroupId
            ) {
              tmp.push(group.parentGroup.parentGroup.parentGroup.name);
              if (
                group.parentGroup.parentGroup.parentGroup.parentGroup &&
                group.parentGroup.parentGroup.parentGroup.parentGroup.id !== rootGroupId
              ) {
                tmp.push(group.parentGroup.parentGroup.parentGroup.parentGroup.name);
              }
            }
          }
        }

        const parentsName = tmp.length > 0 ? tmp.reverse().join(' -> ') : null;
        groups.push({ parentsName, name: group.name, id: group.id, code: group.code });
      });

      groups.sort(alphaSort);
    }

    return {
      groups,
      isLoading,
      error,
      isValidating,
      mutate,
    };
  }, [data, error, isLoading, isValidating, mutate, rootGroupId]);

  return memoizedValue;
}

export function useGetPermissions(roleId: number) {
  const { data: all_permissions } = useSWR(`/permissions`, fetcher, options);
  const {
    data: permissions,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(`/rolepermissions/${roleId}`, fetcher, options);

  const memoizedValue = useMemo(() => {
    let role_permissions = [] as any[];

    if (permissions && all_permissions) {
      if (permissions.length > 0) {
        // Role has permissions
        const group = groupRolePermissions(permissions);

        role_permissions = group[0].permissions;
        for (let i = 0; i < all_permissions.length; i += 1) {
          if (
            role_permissions.filter((e) => e.permissionId === all_permissions[i].id).length <= 0
          ) {
            role_permissions.push({
              id: 0,
              description: all_permissions[i].permissions_trans[0].description,
              permissionId: all_permissions[i].id,
              name: all_permissions[i].permissions_trans[0].name,
              group: all_permissions[i].permissions_trans[0].group,
              active: false,
              parentPermissionId: all_permissions[i].parentPermissionId,
            });
          }
        }
      } else {
        // Role has no permissions
        for (let i = 0; i < all_permissions?.length; i += 1) {
          role_permissions.push({
            id: 0,
            description: all_permissions[i].permissions_trans[0].description,
            permissionId: all_permissions[i].id,
            name: all_permissions[i].permissions_trans[0].name,
            group: all_permissions[i].permissions_trans[0].group,
            active: false,
            parentPermissionId: all_permissions[i].parentPermissionId,
          });
        }
      }
    }

    return {
      permissions: groupPermissions(role_permissions),
      isLoading,
      error,
      isValidating,
      mutate,
    };
  }, [all_permissions, error, isLoading, isValidating, mutate, permissions]);

  return memoizedValue;
}

export async function resetHrbpVisibilityGroup(id: string) {
  const res = await axiosInstance.delete(`/hrbpVisibilityGroup/${id}`);
  return res.data;
}

export async function saveHrbpVisibilityGroup(data: any) {
  const res = await axiosInstance.post(`/hrbpVisibilityGroup`, data);
  return res.data;
}

export async function createRolePermission(data: any) {
  const res = await axiosInstance.post(`/rolepermissions`, data);
  return res.data;
}

export async function deleteRolePermission(id: number) {
  const res = await axiosInstance.delete(`/rolepermissions/${id}`);
  return res.data;
}

export async function importRoles(data: object) {
  const res = await axiosInstance.post('/import/roles', data);
  return res.data;
}

export async function importDeleteRRoles(data: object) {
  const res = await axiosInstance.post('/import/rolesremove', data);
  return res.data;
}
