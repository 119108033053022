import axiosInstance from 'src/utils/axios';

// -------------------------------------------------

export async function createActionPlan(data: any) {
  const res = await axiosInstance.post(`/actionplan`, data);
  return res.data;
}

export async function updateActionPlan(id: number, data: any) {
  const res = await axiosInstance.patch(`/actionplan/${id}`, data);
  return res.data;
}

export async function deleteActionPlan(id: number) {
  const res = await axiosInstance.delete(`/actionplan/${id}`);
  return res.data;
}
