import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './app';
import ReduxProvider from './redux/redux-provider';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <ReduxProvider>
      <HashRouter>
        <Suspense>
          <App />
        </Suspense>
      </HashRouter>
    </ReduxProvider>
  </HelmetProvider>
);
