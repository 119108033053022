import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

type EmployeeStateType = {
  globalLoading: boolean;
  companyLogoChanged: boolean;
  draft: boolean;
  surveySendSuccessDialog: {
    open: boolean;
    targetCount: number;
    sendNow: boolean;
    expireAfterDays: number;
    nextSend: string | null;
    reminderAfterDays: number[] | null;
  };
};

const initialState: EmployeeStateType = {
  globalLoading: false,
  companyLogoChanged: false,
  draft: false,
  surveySendSuccessDialog: {
    open: false,
    targetCount: 0,
    sendNow: false,
    expireAfterDays: 0,
    nextSend: null,
    reminderAfterDays: null,
  },
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
    setCompanyLogoChanged: (state, action) => {
      state.companyLogoChanged = action.payload;
    },
    setDraft: (state, action) => {
      state.draft = action.payload;
    },
    setSurveySendSuccessDialog: (state, action) => {
      state.surveySendSuccessDialog = action.payload;
    },
    resetSurveySendSuccessDialog: (state) => {
      state.surveySendSuccessDialog = {
        open: false,
        targetCount: 0,
        sendNow: false,
        expireAfterDays: 0,
        nextSend: null,
        reminderAfterDays: null,
      };
    },
  },
});

// Reducer actions
export const {
  setGlobalLoading,
  setCompanyLogoChanged,
  setDraft,
  setSurveySendSuccessDialog,
  resetSurveySendSuccessDialog,
} = slice.actions;

// Reducer
export default slice.reducer;
