import { SketchPicker } from 'react-color';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, Popover } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { usePopover } from '../custom-popover';

// ----------------------------------------------------------------------

type Props = {
  name: string;
  width?: number | string;
  height?: number | string;
  onClose?: () => void;
};

export default function RHFColorPicker({
  name,
  width = 90,
  height = 41,
  onClose,
  ...other
}: Props) {
  const { control } = useFormContext();

  const popover = usePopover();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Box
            sx={{
              padding: '6px',
              background: '#fff',
              border: '1px dashed rgba(145, 158, 171, 0.2);',
              borderRadius: '1px',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={popover.onOpen}
          >
            <Box
              sx={{
                width,
                height,
                borderRadius: '2px',
                backgroundColor: field.value,
              }}
            />
          </Box>
          <Popover
            open={Boolean(popover.open)}
            anchorEl={popover.open}
            onClose={() => {
              popover.onClose();
              if (onClose) {
                onClose();
              }
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <SketchPicker
              color={field.value || ''}
              onChange={(color) => field.onChange(color.hex)}
            />
          </Popover>
          {/* {popup.value ? (
            <Box sx={{ position: 'absolute', zIndex: 1300 }}>
              <Box
                sx={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                onClick={popup.onFalse}
              />
              <SketchPicker
                color={field.value || ''}
                onChange={(color) => field.onChange(color.hex)}
              />
            </Box>
          ) : null} */}
        </>
      )}
    />
  );
}
