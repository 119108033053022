import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';
import { objectToQueryString } from 'src/helpers/survey-dashboard';
import { useAuthContext } from 'src/auth/hooks';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetDriverDashboardStats(
  planningId: number | null,
  roundId: number | null,
  driverId: number | null,
  custom: boolean = false,
  segments_query = {},
  include_children = true
) {
  let URL = null;

  if (roundId) {
    URL = `/driversdashboard/dashboard?planning_id=${planningId}&round_id=${roundId}&driverId=${driverId}&custom=${custom}&${objectToQueryString(
      segments_query
    )}&include_children=${include_children}`;
  }

  const { data: stats, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      stats,
      isLoading,
      isEmpty: !isLoading && !stats,
      error,
      isValidating,
      mutate,
    }),
    [stats, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetDriverSuggestions(driverId: number | null, custom: boolean = false) {
  const URL = `/driver/${driverId}?custom=${custom}`;

  const { data: driver, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      driver,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [driver, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetElearnings(driverId: number | null, custom: boolean = false) {
  const URL = `/learning?id=${driverId}?custom=${custom}`;

  const {
    data: elearnings,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      elearnings,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [elearnings, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function getSubdriverDistribution(
  roundId: number | null,
  driverId: number | null,
  subdriverId: number | null,
  custom: boolean = false,
  segments_query = {}
) {
  const res = await axiosInstance.get(
    `/driversdashboard/subdriverdistribution?round_id=${roundId}&driverId=${driverId}&subDriverId=${subdriverId}&custom=${custom}&${objectToQueryString(
      segments_query
    )}`
  );
  return res.data;
}
