import { useSelector, useDispatch } from 'react-redux';
import { forwardRef, useEffect, useState } from 'react';

import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

import { useAuthContext } from 'src/auth/hooks';
import { getCompanyLogo } from 'src/helpers/company';
import { setCompanyLogoChanged } from 'src/redux/slices/common';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  small?: boolean;
  width?: number | string;
}

const CompanyLogo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, width = 140, small = false, sx, ...other }, ref) => {
    const dispatch = useDispatch();
    const { user } = useAuthContext();

    const [companyLogo, setCompanyLogo] = useState(getCompanyLogo(user?.companyId));

    const { companyLogoChanged } = useSelector((state: any) => state.common);

    useEffect(() => {
      if (companyLogoChanged) {
        setCompanyLogo(`${getCompanyLogo(user?.companyId)}?r=${Math.random()}`); // `?r=${Math.random()}` is to force refresh image `src
        dispatch(setCompanyLogoChanged(false));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyLogoChanged]);

    // OR using local (public folder)
    // -------------------------------------------------------

    const logo = <Box component="img" src={companyLogo} sx={{ width, cursor: 'pointer', ...sx }} />;

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default CompanyLogo;
