import Pusher from 'pusher-js';
import { m } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import api from 'src/api';
import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { PUSHER_KEY } from 'src/constants/common';
import { ENGAGEMENT_DRIVER_ID } from 'src/constants/survey';
import { setSurvey } from 'src/redux/slices/survey-dashboard';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';

import NotificationItem from './notification-item';
import ImportEmployeesDialog from './notification-dialogs/import-employees-dialog';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { t } = useTranslate();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const drawer = useBoolean();
  const importEmployeesDialog = useBoolean();

  const smUp = useResponsive('up', 'sm');

  const [selectedNotification, setSelectedNotification] = useState<any | null>(null);

  // const [notifications, setNotifications] = useState(_notifications);

  const { notifications, mutate } = api.notification.useGetNotifications(user?.id, 10, false);
  const { notifications: newNotification, mutate: mutateNewNotifications } =
    api.notification.useGetNotifications(user?.id, null, true);

  useEffect(() => {
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: 'eu',
    });
    const channel = pusher.subscribe(`pusher-channel-${user?.id}`);

    channel.bind('NEW_NOTIFICATION', (data: any) => {
      snackbarByType(data?.payload?.type);
      mutate();
      mutateNewNotifications();
    });

    return () => {
      pusher.unsubscribe(`pusher-channel-${user?.id}`);
      pusher.disconnect();
    };
  }, []);

  const totalUnRead = newNotification?.length;

  const handleMarkAllAsRead = () => {
    try {
      api.notification.clearNotifications();
      mutate();
      mutateNewNotifications();
    } catch (err) {
      console.error(err);
    }
  };

  const snackbarByType = (type: string) => {
    let message = '';
    let variant = 'info' as any;

    switch (type) {
      case 'IMPORT_EMPLOYEES_COMPLETED':
        message = 'Import employees completed';
        variant = 'success';
        break;
      case 'IMPORT_EMPLOYEES_FAILED':
        message = 'Import employees failed';
        variant = 'error';
        break;
      default:
        message = 'Notification';
        break;
    }

    enqueueSnackbar(message, { variant });
  };

  const onClick = async (notification: any) => {
    try {
      setSelectedNotification(notification);
      await api.notification.markAsRead(notification.id);

      mutate();
      mutateNewNotifications();

      if (
        notification.type === 'IMPORT_EMPLOYEES_COMPLETED' ||
        notification.type === 'IMPORT_EMPLOYEES_FAILED'
      ) {
        importEmployeesDialog.onTrue();
      }

      if (
        [
          'ACTION_UPDATED',
          'ACTION_STATUS_UPDATED',
          'ACTION_CLOSED',
          'ACTION_CANCELLED',
          'ACTION_ASSIGNED',
          'COMMENT_CREATED',
          'COMMENT_MODIFIED',
        ].includes(notification.type)
      ) {
        if (notification.payload?.creatorId === user?.id) {
          drawer.onFalse();
          navigate(paths.actions.actionPlan, {
            state: {
              actionId: notification.payload?.actionId,
              actionPlanId: notification.payload?.actionPlanId,
            },
          });
          return;
        }

        drawer.onFalse();
        navigate(paths.actions.myActions, { state: { actionId: notification.payload?.actionId } });
      }

      if (notification.type === 'FEEDBACK') {
        drawer.onFalse();

        if (notification.payload?.planningId && notification.payload?.roundId) {
          dispatch(
            setSurvey({
              planningId: notification.payload?.planningId,
              roundId: notification.payload?.roundId,
            })
          );

          if (notification.payload?.surveyTypeId !== ENGAGEMENT_DRIVER_ID) {
            navigate(paths.customSurveyDashboard.feedback, {
              state: { feedbackId: notification.payload?.feedbackId },
            });
          } else {
            navigate(paths.surveyDashboard.feedback, {
              state: { feedbackId: notification.payload?.feedbackId },
            });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications?.map((notification: any) => (
          <NotificationItem key={notification.id} notification={notification} onClick={onClick} />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
          <IconButton onClick={handleMarkAllAsRead}>
            <Iconify icon="solar:settings-bold-duotone" />
          </IconButton>
        </Stack> */}

        {/* <Divider /> */}

        {renderList}

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            size="large"
            onClick={() => {
              navigate(paths.notifications);
              drawer.onFalse();
            }}
          >
            {t('notifications.view_all')}
          </Button>
        </Box>
      </Drawer>

      <ImportEmployeesDialog
        open={importEmployeesDialog.value}
        onClose={importEmployeesDialog.onFalse}
        notification={selectedNotification}
      />
    </>
  );
}
