import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

import { useAuthContext } from 'src/auth/hooks';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetActionLibrary() {
  const URL = `/actionsuggestions`;

  const { user } = useAuthContext();
  const associateActionWithSubdriver = useMemo(
    () => user?.settings?.associate_action_with_subdriver || false,
    [user]
  );

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  if (data) {
    data.standard_drivers = data.standard_drivers.filter(
      (d: any) => d.pulse_question_categories_trans[0].name !== 'Engagement'
    );

    data.standard_drivers.forEach((driver: any) => {
      driver.suggestions = [];

      if (associateActionWithSubdriver) {
        driver.pulse_question_subcats.forEach((subdriver: any) => {
          subdriver.action_suggestions.forEach((suggestion: any) => {
            driver.suggestions.push({
              suggestion: suggestion.action_suggestions_trans[0].suggestion,
              standard: true,
              pulseQuestionSubcatId: subdriver.id,
              pulseQuestionCategoryId: driver.id,
              subdriver: {
                name: subdriver.pulse_question_subcats_trans[0].name,
                standard: true,
              },
            });
          });

          subdriver.custom_action_suggestions.forEach((suggestion: any) => {
            driver.suggestions.push({
              suggestion: suggestion.suggestion,
              standard: false,
              pulseQuestionSubcatId: subdriver.id,
              pulseQuestionCategoryId: driver.id,
              subdriver: {
                name: subdriver.pulse_question_subcats_trans[0].name,
                standard: true,
              },
            });
          });
        });

        driver.custom_pulse_question_subcats.forEach((subdriver: any) => {
          subdriver.custom_action_suggestions.forEach((suggestion: any) => {
            driver.suggestions.push({
              suggestion: suggestion.suggestion,
              standard: false,
              customPulseQuestionSubcatId: subdriver.id,
              pulseQuestionCategoryId: driver.id,
              subdriver: {
                name: subdriver.name,
                standard: false,
              },
            });
          });
        });
      } else {
        driver.action_suggestions.forEach((suggestion: any) => {
          driver.suggestions.push({
            suggestion: suggestion.action_suggestions_trans[0].suggestion,
            standard: true,
            pulseQuestionCategoryId: driver.id,
            driver: {
              name: driver.pulse_question_categories_trans[0].name,
              standard: true,
            },
          });
        });

        driver.custom_action_suggestions.forEach((suggestion: any) => {
          driver.suggestions.push({
            suggestion: suggestion.suggestion,
            standard: false,
            pulseQuestionCategoryId: driver.id,
            driver: {
              name: driver.pulse_question_categories_trans[0].name,
              standard: true,
            },
          });
        });
      }
    });

    data.custom_drivers.forEach((driver: any) => {
      driver.suggestions = [];

      if (associateActionWithSubdriver) {
        driver.custom_pulse_question_subcats.forEach((subdriver: any) => {
          subdriver.custom_action_suggestions.forEach((suggestion: any) => {
            driver.suggestions.push({
              suggestion: suggestion.suggestion,
              standard: false,
              customPulseQuestionSubcatId: subdriver.id,
              customPulseQuestionCategoryId: driver.id,
              subdriver: {
                name: subdriver.name,
                standard: false,
              },
            });
          });
        });
      } else {
        driver.custom_action_suggestions.forEach((suggestion: any) => {
          driver.suggestions.push({
            suggestion: suggestion.suggestion,
            standard: false,
            customPulseQuestionCategoryId: driver.id,
            driver: {
              name: driver.name,
              standard: false,
            },
          });
        });
      }
    });
  }

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetDriver(type: 'STANDARD' | 'CUSTOM', id?: number | null) {
  const URL = id
    ? `/${type === 'STANDARD' ? 'pulsequestioncategories' : 'custompulsequestioncategory'}/${id}`
    : null;

  const { data: driver, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      driver,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [driver, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetActionPlans(showLocked: boolean) {
  const URL = `/actionplans?showprivateactions=${showLocked ? 'true' : 'false'}`;

  const {
    data: actionPlans,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      actionPlans,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [actionPlans, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function updateCustomDriverSuggestions(data: any) {
  const res = await axiosInstance.patch(`/customDriverSuggestions`, data);
  return res.data;
}

export async function updateCustomSubdriverSuggestions(data: any) {
  const res = await axiosInstance.patch(`/customSubdriverSuggestions`, data);
  return res.data;
}

export async function updateDriverSuggestions(data: any) {
  const res = await axiosInstance.patch(`/driverSuggestions`, data);
  return res.data;
}

export async function updateSubdriverSuggestions(data: any) {
  const res = await axiosInstance.patch(`/subdriverSuggestions`, data);
  return res.data;
}
