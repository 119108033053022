import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { bgBlur } from 'src/theme/css';

import Logo from 'src/components/logo';

import { HEADER } from '../config-layout';
import HeaderShadow from './header-shadow';

// ----------------------------------------------------------------------

export default function HeaderSimple() {
  const theme = useTheme();

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          height: {
            md: HEADER.H_DESKTOP_OFFSET,
          },
        }}
      >
        <Logo />

        <Stack direction="row" alignItems="center" spacing={1}>
          {/* <SettingsButton /> */}

          {/* <Link
            href={paths.faqs}
            component={RouterLink}
            color="inherit"
            sx={{ typography: 'subtitle2' }}
          >
            Need help?
          </Link> */}
        </Stack>
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}
