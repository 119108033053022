import i18n from 'src/locales/i18n';
import { APP_VERSION } from 'src/constants/common';

export function getAppVersion() {
  const environment = process.env.NODE_ENV;
  const serverVersion = '1.0.0';

  return `${environment} version: ${APP_VERSION} | server version: ${serverVersion}`;
}

export function getPageTitle(pathname: string) {
  if (pathname === '/') return i18n.t('menu.dashboard');

  if (pathname === '/survey-dashboard') return i18n.t('survey_menu.results');
  if (pathname === '/survey-dashboard/analysis') return i18n.t('survey_menu.analysis');
  if (pathname === '/survey-dashboard/heatmap') return i18n.t('survey_menu.heatmap');
  if (pathname === '/survey-dashboard/feedback') return i18n.t('survey_menu.feedback');
  if (pathname === '/survey-dashboard/segments') return i18n.t('survey_menu.segments');
  if (pathname === '/survey-dashboard/segment-dashboard') return i18n.t('menu.dashboard');
  if (pathname === '/survey-dashboard/driver-dashboard') return i18n.t('menu.dashboard');
  if (pathname === '/custom-survey-dashboard') return i18n.t('survey_menu.results');
  if (pathname === '/custom-survey-dashboard/analysis') return i18n.t('survey_menu.analysis');
  if (pathname === '/custom-survey-dashboard/heatmap') return i18n.t('survey_menu.heatmap');
  if (pathname === '/custom-survey-dashboard/feedback') return i18n.t('survey_menu.feedback');
  if (pathname === '/custom-survey-dashboard/segments') return i18n.t('survey_menu.segments');
  if (pathname === '/custom-survey-dashboard/segment-dashboard') return i18n.t('menu.dashboard');
  if (pathname === '/schedules') return i18n.t('menu.surveys.my_surveys');
  if (pathname === '/schedules/new') return i18n.t('menu.surveys.new_survey');
  if (pathname === '/schedules/edit') return i18n.t('menu.surveys.edit_survey');
  if (pathname === '/schedules/detail') return i18n.t('menu.surveys.survey_detail');
  if (pathname === '/actions/action-plan') return i18n.t('menu.act.action_plan');
  if (pathname === '/actions/library') return i18n.t('menu.act.library');
  if (pathname === '/actions/my-actions') return i18n.t('menu.act.my_actions');
  if (pathname === '/actions/new') return i18n.t('actions_page.new_action');
  if (pathname.includes('/actions/edit')) return i18n.t('edit_action.modify_action');
  if (pathname === '`/needs') return i18n.t('menu.knowledge_center');
  if (pathname === '/needs/edit') return i18n.t('menu.knowledge_center');
  if (pathname === '/company/employees') return i18n.t('menu.admin.company.employees');
  if (pathname === '/company/employees/new') return i18n.t('menu.admin.company.employees');
  if (pathname.includes('/company/employees/edit')) return i18n.t('menu.admin.company.employees');
  if (pathname === '/company/roles') return i18n.t('menu.admin.company.roles');
  if (pathname === '/company/groups') return i18n.t('menu.admin.company.groups');
  if (pathname === '/company/segments') return i18n.t('menu.admin.company.segments');
  if (pathname === '/company/settings') return i18n.t('menu.admin.company.settings');
  if (pathname === '/company/security') return i18n.t('menu.admin.company.security');
  if (pathname === '/survey/data') return i18n.t('menu.admin.survey.data');
  if (pathname === '/survey/mailing') return i18n.t('menu.admin.survey.mailing');
  if (pathname === '/survey/topics') return i18n.t('menu.admin.survey.topics');
  if (pathname === '/survey/topics/new') return i18n.t('menu.admin.survey.topics');
  if (pathname.includes('/survey/topics/edit')) return i18n.t('menu.admin.survey.topics');
  if (pathname === '/elearning') return i18n.t('learning.elearning_content');
  if (pathname === '/subsidiaries-dashboard') return i18n.t('common.subsidiaries_dashboard');
  if (pathname === '/needs') return i18n.t('menu.knowledge_center');
  if (pathname === '/notifications') return i18n.t('common.notifications');

  return pathname;
}

interface Element {
  [key: string]: any;
  nodes?: Element[];
}
export function arraytotree(elements: any[], childKey: string, parentkey: string) {
  /**
   * Unflattens an array to a tree with runtime O(n)
   *
   * This algorithm was taken from Philip Stanislaus's
   * "Performant Array to Tree" which has O(n) complexity.
   * It builds the tree in a single pass.
   * @link https://github.com/philipstanislaus
   * @link https://www.npmjs.com/package/performant-array-to-tree
   */

  /**
   * The nested tree.
   * @type {*[]}
   */

  const rootItems: Element[] = [];
  // (1) Create a holder for each item.

  const lookup: { [key: string]: Element } = {};

  elements.forEach((item) => {
    const itemId = item[childKey];
    const parentId = item[parentkey];

    // (2) Create a placeholder for each item in the lookup.
    // Details are added later.

    if (!lookup[itemId]) {
      lookup[itemId] = { nodes: [] };
    }

    // (3) Add the details of the item.
    lookup[itemId] = { ...item, nodes: lookup[itemId].nodes };

    // (4) Create a variable for the current item.
    const TreeItem = lookup[itemId];

    // (5) Determine where the item goes in the tree.
    // If the item has no parentId, it is the root node.
    if (
      parentId === null ||
      parentId === undefined ||
      parentId === '' ||
      !elements.some((e) => e[childKey] === parentId)
    ) {
      rootItems.push(TreeItem);
    } else {
      /*
       * If the item has a parentId, add it to the tree.
       */
      // (6) Add a placeholder for parent of the current item.
      if (!lookup[parentId]) lookup[parentId] = { nodes: [] };

      // (7) Add the current item to its parent.
      lookup[parentId].nodes?.push(TreeItem);
    }
  });

  return rootItems;
}
