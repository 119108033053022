import useSWR from 'swr';
import { useMemo } from 'react';

import { fetcher } from 'src/utils/axios';

// -------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};
export function useGetQuestionsBySegment(planningId: number | null, roundId: number | null) {
  let URL = null;

  if (roundId && planningId) {
    URL = `/customdashboard/questionsbysegment?planningId=${planningId}&roundId=${roundId}`;
  }

  const { data: drivers, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      drivers,
      isLoading,
      isEmpty: !isLoading && !drivers,
      error,
      isValidating,
      mutate,
    }),
    [drivers, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetAgeSegmentQuestions(roundId: number | null) {
  let URL = null;
  if (roundId) {
    URL = `/segments/ageSegmentbyquestions?roundId=${roundId}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetTeneurSegmentQuestions(roundId: number | null) {
  let URL = null;
  if (roundId) {
    URL = `/segments/teneurSegmentbyquestions?roundId=${roundId}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetSexSegmentQuestions(roundId: number | null) {
  let URL = null;
  if (roundId) {
    URL = `/segments/sexSegmentbyquestions?roundId=${roundId}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetClassSegmentQuestions(roundId: number | null) {
  let URL = null;
  if (roundId) {
    URL = `/segments/classSegmentbyquestions?roundId=${roundId}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetLocationSegmentQuestions(roundId: number | null) {
  let URL = null;
  if (roundId) {
    URL = `/segments/locationSegmentbyquestions?roundId=${roundId}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}
