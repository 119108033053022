type IEnv = 'development' | 'production' | 'staging' | 'attijari' | string | undefined;

// env
// export const env: IEnv = process.env.NODE_ENV; // development || production || staging || attijari
// export const env: IEnv = 'staging';
// export const env: IEnv = 'attijari';
export const env: IEnv = 'production';

const development = {
  api: {
    positeams: 'http://localhost:8082/api',
    inwi: 'http://localhost:8082/api',
  },
  mawaridSurveyPortalUrl: 'https://mawaridsurvey.positeams.com',
};

const production = {
  api: {
    positeams: 'https://app.positeams.com/api',
    inwi: 'https://inwiserver.positeams.com/api',
  },
  mawaridSurveyPortalUrl: 'https://hrsurvey.positeams.com',
};

const attijari = {
  api: {
    positeams: 'https://app.positeams.com/api',
    inwi: 'https://inwiserver.positeams.com/api',
  },
  mawaridSurveyPortalUrl: 'https://hrsurvey.positeams.com',
};

const staging = {
  api: {
    positeams: 'http://staging.positeams.com:8082/api',
    inwi: 'http://staging.positeams.com:8082/api',
  },
  mawaridSurveyPortalUrl: 'http://staging.positeams.com:71',
};

const getEnv: any = () => {
  switch (env) {
    case 'development':
      return development;
    case 'production':
      return production;
    case 'attijari':
      return attijari;
    case 'staging':
      return staging;
    default:
      return {};
  }
};

export default getEnv();
