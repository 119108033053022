import { useState, useEffect } from 'react';

import api from 'src/api';

export default function useHasPermission(route: string, type: 'R' | 'W') {
  const [hasAccess, setHasAccess] = useState(false);

  const checkPermission = async () => {
    const res = await api.auth.hasPermission(route);
    const { has_permission, has_permission_write } = res.data;

    if (type === 'W') {
      setHasAccess(has_permission_write);
    }
    if (type === 'R') {
      setHasAccess(has_permission);
    }
  };

  useEffect(() => {
    checkPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, type]);

  return hasAccess;
}
