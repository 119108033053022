import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetCompanyDetails() {
  const URL = `/company`;

  const { data: company, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      company,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [company, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetCompanySettings() {
  const URL = `/companysettings`;

  const {
    data: companySettings,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      companySettings,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [companySettings, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function updateCompanySettings(data: any) {
  const res = await axiosInstance.patch('/companysettings', data);
  return res.data;
}

export async function uploadCompanyLogo(data: any) {
  const res = await axiosInstance.post('/uploadlogo', data);
  return res.data;
}

export async function resetExternalCompanyLogo() {
  const res = await axiosInstance.post('/company/resetExternalCompanyLogo');
  return res.data;
}
