import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';
import { useAuthContext } from 'src/auth/hooks';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetStandardDrivers() {
  const { user } = useAuthContext();

  const URL = `/companydriverweights?companyId=${user?.companyId}`;

  const {
    data: standardDrivers,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  if (standardDrivers) {
    standardDrivers.forEach((d: any) => {
      if (!d.company_driver_weight) {
        d.company_driver_weight = { weight: 1 };
      }

      d.name =
        d.pulse_question_categories_trans.find(
          (t: any) => t.locale.toLowerCase() === user?.locale.toLowerCase()
        ).name || '--';
    });
  }

  const memoizedValue = useMemo(
    () => ({
      standardDrivers,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [standardDrivers, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetCustomDrivers() {
  const { user } = useAuthContext();

  const URL = `/companycustomdriverweights?companyId=${user?.companyId}`;

  const {
    data: customDrivers,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  if (customDrivers) {
    customDrivers.forEach((d: any) => {
      if (!d.company_driver_weight) {
        d.company_driver_weight = { weight: 1 };
      }
    });
  }

  const memoizedValue = useMemo(
    () => ({
      customDrivers,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [customDrivers, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function updateCompanyDriverWeights(data: any) {
  const res = await axiosInstance.post('/updateCompanyDriverWeights', data);
  return res.data;
}
