import { m } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Theme, SxProps } from '@mui/material/styles';

import api from 'src/api';
import { ForbiddenIllustration } from 'src/assets/illustrations';

import { varBounce, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  route: string;
  type: 'R' | 'W';
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function PermissionGuard({
  hasContent,
  route,
  type,
  children,
  sx,
}: RoleBasedGuardProp) {
  const { t } = useTranslation();

  const [hasAccess, setHasAccess] = useState(null);

  const checkPermission = async () => {
    const res = await api.auth.hasPermission(route);
    const { has_permission, has_permission_write } = res.data;

    if (type === 'W') {
      setHasAccess(has_permission_write);
    }
    if (type === 'R') {
      setHasAccess(has_permission);
    }
  };

  useEffect(() => {
    checkPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, type]);

  if (hasAccess === null) {
    return null;
  }

  if (!hasAccess) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', mt: '22px', ...sx }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {t('common.permission_denied')}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            {t('common.permission_denied_desc')}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
