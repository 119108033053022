import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { hideSurveyMenu, setSurvey, showSurveyMenu } from 'src/redux/slices/survey-dashboard';

// ----------------------------------------------------------------------

const surveyDashboardRoutes = [
  '/survey-dashboard',
  '/survey-dashboard/analysis',
  '/survey-dashboard/heatmap',
  '/survey-dashboard/feedback',
  '/survey-dashboard/segments',
  '/survey-dashboard/segment-dashboard',
  '/survey-dashboard/driver-dashboard',
  // custom survey dashboard
  '/custom-survey-dashboard',
  '/custom-survey-dashboard/heatmap',
  '/custom-survey-dashboard/feedback',
  '/custom-survey-dashboard/segments',
  '/custom-survey-dashboard/segment-dashboard',
];

export function useRouteChanged() {
  const location = useLocation();
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (surveyDashboardRoutes.includes(location.pathname)) {
      dispatch(showSurveyMenu({}));
    } else {
      dispatch(hideSurveyMenu({}));
    }

    // if (surveyDashboardRoutes.includes(location.pathname) && state?.surveyId) {
    //   dispatch(setSurvey({ planningId: state?.surveyId, roundId: state?.roundId }));
    // }
  }, [location]);

  return null;
}
