import { API_URL, COMPANY_EXTERNAL_LOGO_PATH, COMPANY_LOGO_PATH } from 'src/constants/common';

export function getCompanyLogo(companyId: number) {
  return `${API_URL}${COMPANY_LOGO_PATH}${companyId}.png`;
}

export function getExternalCompanyLogo(companyId: number) {
  return `${API_URL}${COMPANY_EXTERNAL_LOGO_PATH}${companyId}/${companyId}.png`;
}

export function getSubsidiaryLogo(subsidiary: string, companyId: number) {
  return `${API_URL}${COMPANY_EXTERNAL_LOGO_PATH}${companyId}/${subsidiary}.png`;
}
