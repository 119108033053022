import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

import { objectToQueryString } from 'src/helpers/survey-dashboard';

// -------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetAgeSegment(
  roundId: number | null,
  isCustomSurvey: boolean,
  queryParams: any
) {
  let URL = null;
  if (roundId) {
    URL = `/segments/ageSegment?roundId=${roundId}&custom=${
      isCustomSurvey ? 1 : 0
    }&${objectToQueryString(queryParams)}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetTeneurSegment(
  roundId: number | null,
  isCustomSurvey: boolean,
  queryParams: any
) {
  let URL = null;
  if (roundId) {
    URL = `/segments/teneurSegment?roundId=${roundId}&custom=${
      isCustomSurvey ? 1 : 0
    }&${objectToQueryString(queryParams)}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetSexSegment(
  roundId: number | null,
  isCustomSurvey: boolean,
  queryParams: any
) {
  let URL = null;
  if (roundId) {
    URL = `/segments/sexSegment?roundId=${roundId}&custom=${
      isCustomSurvey ? 1 : 0
    }&${objectToQueryString(queryParams)}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetClassSegment(
  roundId: number | null,
  isCustomSurvey: boolean,
  queryParams: any
) {
  let URL = null;
  if (roundId) {
    URL = `/segments/classSegment?roundId=${roundId}&custom=${
      isCustomSurvey ? 1 : 0
    }&${objectToQueryString(queryParams)}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetLocationSegment(
  roundId: number | null,
  isCustomSurvey: boolean,
  queryParams: any
) {
  let URL = null;
  if (roundId) {
    URL = `/segments/locationSegment?roundId=${roundId}&custom=${
      isCustomSurvey ? 1 : 0
    }&${objectToQueryString(queryParams)}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function validateSegment(data: any) {
  const res = await axiosInstance.post('/segmentsettings/validateSegment', data);
  return res.data;
}

export async function saveSegment(data: any) {
  const res = await axiosInstance.post('/segmentsettings/saveSegment', data);
  return res.data;
}

export async function resetSegment(data: any) {
  const res = await axiosInstance.post('/segmentsettings/resetSegment', data);
  return res.data;
}

export async function saveClassSegment(data: any) {
  const res = await axiosInstance.post('/segmentsettings/saveClassSegment', data);
  return res.data;
}

export async function saveLocationSegment(data: any) {
  const res = await axiosInstance.post('/segmentsettings/saveLocationSegment', data);
  return res.data;
}
