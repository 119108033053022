import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetNeeds() {
  const URL = `/pyramidofneeds/drivers`;

  const { data: needs, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      needs,
      isLoading,
      isEmpty: !isLoading && !needs?.length,
      error,
      isValidating,
      mutate,
    }),
    [needs, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetAllDrivers() {
  const URL = `/pyramidofneeds/all-drivers`;

  const { data: drivers, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      drivers,
      isLoading,
      isEmpty: !isLoading && !drivers?.length,
      error,
      isValidating,
      mutate,
    }),
    [drivers, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function addPyramidOfNeedsDrivers(data: any) {
  const res = await axiosInstance.post(`/pyramidofneeds/drivers`, data);
  return res.data;
}

export async function deletePyramidOfNeedsDriver(id: number) {
  const res = await axiosInstance.delete(`/pyramidofneeds/drivers/${id}`);
  return res.data;
}
