/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

import { fToNow } from 'src/utils/format-time';

import { getStatusLabel } from 'src/helpers/action';
import { error, primary, success } from 'src/theme/palette';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type NotificationItemProps = {
  notification: any;
  onClick: (notification: any) => void;
};

export default function NotificationItem({ notification, onClick }: NotificationItemProps) {
  const { t } = useTranslation();

  const renderIcon = (
    <ListItemAvatar>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
        }}
      >
        {getIcon(notification.type)}
      </Stack>
    </ListItemAvatar>
  );

  const renderText = (
    <ListItemText
      disableTypography
      primary={getBody(notification, t)}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: 'caption', color: 'text.disabled' }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: 'currentColor',
                mx: 0.5,
                borderRadius: '50%',
              }}
            />
          }
        >
          {fToNow(notification.createdAt)}
          {/* {notification.category} */}
        </Stack>
      }
    />
  );

  const renderUnReadBadge = notification.clearedAt === null && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
      onClick={() => onClick(notification)}
    >
      {renderUnReadBadge}

      {renderIcon}

      <Stack sx={{ flexGrow: 1, pr: 2 }}>{renderText}</Stack>
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function getBody(notification: any, t: any) {
  switch (notification.type) {
    case 'IMPORT_EMPLOYEES_COMPLETED':
      return (
        <Typography variant="body2">
          {`L'importation des employés terminée avec succès`} <i>(clicker pour voir les détails)</i>
        </Typography>
      );
    case 'IMPORT_EMPLOYEES_FAILED':
      return (
        <Typography variant="body2">
          {`L'importation des employés a échoué`} <i>(clicker pour voir les détails)</i>
        </Typography>
      );
    case 'ACTION_UPDATED':
      return (
        <Typography variant="body2">
          <strong>{notification.payload?.creator}</strong>{' '}
          {t('notifications.action_updated', { id: notification.payload?.actionId })}
        </Typography>
      );
    case 'ACTION_STATUS_UPDATED':
      return (
        <Typography variant="body2">
          <strong>{notification.payload?.owner}</strong>{' '}
          {t('notifications.action_status_update', {
            id: notification.payload?.actionId,
            status: getStatusLabel(notification.payload?.actionStatus),
          })}
        </Typography>
      );
    case 'ACTION_CLOSED':
      return (
        <Typography variant="body2">
          <strong>{notification.payload?.creator}</strong>{' '}
          {t('notifications.action_closed', { id: notification.payload?.actionId })}
        </Typography>
      );
    case 'ACTION_CANCELLED':
      return (
        <Typography variant="body2">
          <strong>{notification.payload?.creator}</strong>{' '}
          {t('notifications.action_cancelled', { id: notification.payload?.actionId })}
        </Typography>
      );
    case 'ACTION_ASSIGNED':
      return (
        <Typography variant="body2">
          <strong>{notification.payload?.creator}</strong>{' '}
          {t('notifications.action_assigned', { id: notification.payload?.actionId })}
        </Typography>
      );
    case 'COMMENT_CREATED':
      return (
        <Typography variant="body2">
          <strong>{notification.payload?.creator}</strong>{' '}
          {t('notifications.added_action_comment', { id: notification.payload?.actionId })}
        </Typography>
      );
    case 'COMMENT_MODIFIED':
      return (
        <Typography variant="body2">
          <strong>{notification.payload?.creator}</strong>{' '}
          {t('notifications.modified_action_comment', { id: notification.payload?.actionId })}
        </Typography>
      );
    case 'SURVEY_SENT':
      return (
        <Typography variant="body2">
          {/* <strong>{notification.payload?.creator}</strong>{' '} */}
          {t('notifications.survey')} <strong>{notification.payload?.planningName} </strong>
          {t('notifications.survey_sent')}
        </Typography>
      );
    case 'FEEDBACK':
      return (
        <Typography variant="body2">
          {t('notifications.feedback2', {
            feedbackId: notification.payload?.feedbackId,
          })}{' '}
          <strong>#{notification.payload?.feedbackId}</strong>
          <br />
          {notification.payload?.feedback && (
            <Typography variant="caption">
              <i>{`"${notification.payload?.feedback.slice(0, 50)} ${
                notification.payload?.feedback.length > 50 && '...'
              }"`}</i>
            </Typography>
          )}
        </Typography>
      );
    default:
      return <Typography variant="body2">{notification.title}</Typography>;
  }
}

function getIcon(type: string) {
  switch (type) {
    case 'IMPORT_EMPLOYEES_COMPLETED':
      return <Iconify icon="material-symbols:upload" width={24} height={24} color={primary.main} />;
    case 'IMPORT_EMPLOYEES_FAILED':
      return <Iconify icon="material-symbols:upload" width={24} height={24} color={error.main} />;
    case 'ACTION_UPDATED':
      return <Iconify icon="material-symbols:update" width={24} height={24} color={success.dark} />;
    case 'ACTION_CANCELLED':
      return <Iconify icon="material-symbols:cancel" width={24} height={24} color={error.main} />;
    case 'FEEDBACK':
      return (
        <Iconify icon="material-symbols:feedback" width={24} height={24} color={primary.main} />
      );
    case 'ACTION_ASSIGNED':
      return <Iconify icon="clarity:tasks-solid" width={24} height={24} color={primary.main} />;
    case 'ACTION_CLOSED':
      return <Iconify icon="clarity:tasks-solid" width={24} height={24} color={primary.main} />;
    default:
      return (
        <Iconify icon="mingcute:notification-fill" width={24} height={24} color={primary.main} />
      );
  }
}
