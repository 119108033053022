import useSWR from 'swr';
import { useMemo } from 'react';

import { fetcher } from 'src/utils/axios';

// -------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetDriversByGroup(planningId: number | null, roundId: number | null) {
  let URL = null;

  if (roundId && planningId) {
    URL = `/dashboard/driversbysegment?planningId=${planningId}&roundId=${roundId}`;
  }

  const { data: drivers, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      drivers,
      isLoading,
      isEmpty: !isLoading && !drivers,
      error,
      isValidating,
      mutate,
    }),
    [drivers, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetAgeSegmentDrivers(roundId: number | null) {
  let URL = null;
  if (roundId) {
    URL = `/segments/ageSegmentDrivers?roundId=${roundId}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetTeneurSegmentDrivers(roundId: number | null) {
  let URL = null;
  if (roundId) {
    URL = `/segments/teneurSegmentDrivers?roundId=${roundId}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetSexSegmentDrivers(roundId: number | null) {
  let URL = null;
  if (roundId) {
    URL = `/segments/sexSegmentDrivers?roundId=${roundId}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetClassSegmentDrivers(roundId: number | null) {
  let URL = null;
  if (roundId) {
    URL = `/segments/classSegmentDrivers?roundId=${roundId}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetLocationSegmentDrivers(roundId: number | null) {
  let URL = null;
  if (roundId) {
    URL = `/segments/locationSegmentDrivers?roundId=${roundId}`;
  }

  const { data: segment, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segment,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segment, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}
