import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetSegments() {
  const URL = `/segmentsettings`;

  const { data: segments, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      segments,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [segments, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function validateSegment(data: any) {
  const res = await axiosInstance.post('/segmentsettings/validateSegment', data);
  return res.data;
}

export async function saveSegment(data: any) {
  const res = await axiosInstance.post('/segmentsettings/saveSegment', data);
  return res.data;
}

export async function resetSegment(data: any) {
  const res = await axiosInstance.post('/segmentsettings/resetSegment', data);
  return res.data;
}

export async function saveClassSegment(data: any) {
  const res = await axiosInstance.post('/segmentsettings/saveClassSegment', data);
  return res.data;
}

export async function saveLocationSegment(data: any) {
  const res = await axiosInstance.post('/segmentsettings/saveLocationSegment', data);
  return res.data;
}
