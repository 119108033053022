import useSWR from 'swr';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';

import axiosInstance, { fetcher } from 'src/utils/axios';

import { groupQuestionsPulse, groupCustomQuestionsPulse } from 'src/helpers/question';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetSurveys() {
  const URL = `/plannings`;

  const { data: surveys, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const rounds = [] as any[];

  if (surveys) {
    surveys?.forEach((survey: any) => {
      survey.rounds?.forEach((round: any) => {
        round.survey_type = survey.survey_type.survey_types_trans[0].name;
        round.target_groups = survey.planning_groups;
        round.name = survey.name;
        round.next_send = survey.next_send;
        round.interval = survey.frequency.interval;
        round.surveyTypeId = survey.surveyTypeId;

        rounds.push(round);
      });
    });
  }

  const memoizedValue = useMemo(
    () => ({
      surveys,
      rounds,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [surveys, rounds, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetSurvey(id: number) {
  const URL = `/planning/${id}`;

  const { data: survey, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const rounds: any[] = useMemo(() => [], []);

  if (survey) {
    survey.rounds?.forEach((round: any) => {
      round.survey_type = survey.survey_type.survey_types_trans[0].name;
      round.target_groups = survey.planning_groups;
      round.name = survey.name;
      round.next_send = survey.next_send;
      round.interval = survey.frequency.interval;

      rounds.push(round);
    });
  }

  const memoizedValue = useMemo(
    () => ({
      survey,
      rounds,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [survey, rounds, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetFrequencies() {
  const URL = `/frequencies`;

  const {
    data: frequencies,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      frequencies,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [frequencies, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetLocations() {
  const URL = `/segments/allLocations`;

  const { data: locations, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      locations,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [locations, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetPulseQuestions(load: boolean) {
  const URL = load ? `/pulsequestions/get` : null;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  let questions: any;

  if (data) questions = groupQuestionsPulse(data);

  const memoizedValue = useMemo(
    () => ({
      questions,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [questions, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetCustomPulseQuestions(load: boolean, surveyTypeId: number) {
  // N.Z 04 June 21  ENABLE getting all custom Questions Pulses
  const URL = load
    ? `/custompulsequestions${surveyTypeId ? `?surveyTypeId=${surveyTypeId}` : ''}`
    : null;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  let questions: any;

  if (data) questions = groupCustomQuestionsPulse(data);

  const memoizedValue = useMemo(
    () => ({
      questions,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [questions, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetOpenQuestions(load: boolean) {
  const URL = load ? `/openquestions/get` : null;

  const { data: questions, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  if (questions) {
    questions?.forEach((q: any) => {
      q.description = q?.open_questions_trans[0].description || '';
      q.active = true;
      q.frequency = 0;
      q.custom = false;
    });
  }

  const memoizedValue = useMemo(
    () => ({
      questions,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [questions, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetCustomOpenQuestions(load: boolean, surveyTypeId: number) {
  // N.Z 04 June 21  ENABLE getting all custom Questions Pulses
  const URL = load
    ? `/customopenquestions${surveyTypeId ? `?surveyTypeId=${surveyTypeId}` : ''}`
    : null;

  const { data: questions, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  if (questions) {
    questions?.forEach((q: any) => {
      q.active = true;
      q.frequency = 0;
      q.custom = true;
    });
  }

  const memoizedValue = useMemo(
    () => ({
      questions,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [questions, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetSurveyQuestions(id: number, createMode: boolean = false) {
  // N.Z 04 June 21  ENABLE getting all custom Questions Pulses
  const URL = createMode ? null : `/planningquestions?planningId=${id}`;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const questions: any = useMemo(() => ({}), []);
  let allQuestions: any = useMemo(() => [], []);

  if (data) {
    allQuestions = [];

    // Pulse Questions
    let pulseQuestions = data.filter((q: any) => q.pulseQuestionId);
    pulseQuestions = pulseQuestions?.map((q: any) => ({
      ...q.pulse_question,
      gallup: q.gallup,
      custom: false,
      questionOrder: q.questionOrder,
    }));
    questions.pulseQuestions = groupQuestionsPulse(pulseQuestions);

    // Custom Pulse Questions
    let customPulseQuestions = data.filter((q: any) => q.customPulseQuestionId);
    customPulseQuestions = customPulseQuestions?.map((q: any) => ({
      ...q.custom_pulse_question,
      gallup: q.gallup,
      custom: true,
      questionOrder: q.questionOrder,
    }));
    questions.customPulseQuestions = groupCustomQuestionsPulse(customPulseQuestions);

    // Open Questions
    let openQuestions = data.filter((q: any) => q.openQuestionId);
    openQuestions = openQuestions?.map((q: any) => ({
      ...q.open_question,
      description: q.open_question?.open_questions_trans[0].description || '',
      active: true,
      frequency: q.openQuestionResetAfter,
      custom: false,
      questionOrder: q.questionOrder,
    }));
    questions.openQuestions = openQuestions;

    // Custom Open Questions
    let customOpenQuestions = data.filter((q: any) => q.customOpenQuestionId);
    customOpenQuestions = customOpenQuestions?.map((q: any) => ({
      ...q.custom_open_question,
      active: true,
      frequency: q.openQuestionResetAfter,
      custom: true,
      questionOrder: q.questionOrder,
    }));
    questions.customOpenQuestions = customOpenQuestions;

    // all questions with order (for Order step)
    questions.pulseQuestions.forEach((category: any) => {
      category.items.forEach((question: any) => {
        if (question.active) {
          allQuestions.push({
            pulseQuestionId: question.id,
            question: question.question,
            category: `${category.category} / ${question.subcat}`,
            questionOrder: question.questionOrder,
          });
        }
      });
    });
    questions.customPulseQuestions.forEach((category: any) => {
      category.items.forEach((question: any) => {
        if (question.active) {
          allQuestions.push({
            customPulseQuestionId: question.id,
            question: question.question,
            category: `${category.category} / ${question.subcat}`,
            questionOrder: question.questionOrder,
          });
        }
      });
    });
    questions.openQuestions.forEach((question: any) => {
      if (question.active) {
        allQuestions.push({
          openQuestionId: question.id,
          frequency: question.openQuestionResetAfter,
          question: question.description,
          questionOrder: question.questionOrder,
        });
      }
    });
    questions.customOpenQuestions.forEach((question: any) => {
      if (question.active) {
        allQuestions.push({
          customOpenQuestionId: question.id,
          frequency: question.openQuestionResetAfter,
          question: question.description,
          questionOrder: question.questionOrder,
        });
      }
    });

    allQuestions.sort((a: any, b: any) => a.questionOrder - b.questionOrder);
  }

  const memoizedValue = useMemo(
    () => ({
      allQuestions,
      surveyQuestions: isEmpty(questions) ? undefined : questions,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [allQuestions, questions, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function createSurvey(data: any) {
  const res = await axiosInstance.post('/planning/post', data);
  return res.data;
}

export async function updateSurvey(id: number, data: any) {
  const res = await axiosInstance.patch(`/planning/${id}`, data);
  return res.data;
}

export async function sendSurvey(data: any) {
  const res = await axiosInstance.post(`/surveys/sendsurvey`, data);
  return res.data;
}

export async function sendTestSurvey(data: any) {
  const res = await axiosInstance.post(`/surveys/sendtestsurvey`, data);
  return res.data;
}

export async function getPreviewSurveyURL(data: any) {
  const res = await axiosInstance.post(`/surveys/gettestsurveyurl`, data);
  return res.data;
}

export async function deleteSurvey(id: number) {
  const res = await axiosInstance.delete(`/planning/${id}`);
  return res.data;
}
