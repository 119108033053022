import { createSlice } from '@reduxjs/toolkit';

import { SEGMENT_TYPES } from 'src/constants/segments';

// ----------------------------------------------------------------------

type SurveyDashboardTypes = {
  showSurveyMenu: boolean;
  survey: {
    planningId: number | null;
    roundId: number | null;
    isEngagement: boolean;
    planningName: string;
    participants: number;
    recipients: number;
    participation: number;
    lastSend: string | null;
    expiredAt: string | null;
    history: any[];
  };
  segment: {
    type: 'GROUP' | 'AGE' | 'TENEUR' | 'SEX' | 'CLASS' | 'LOCATION' | null;
    value: string | null;
    name: string | null;
    includeChildren: boolean; // if we want to show group and his children data (useful for group segment type)
    forceCombineView: boolean; // if we want to show force combine view (useful for group segment type)
    hasChildren: boolean;
  } | null;
  driver: {
    id: number | null;
    name: string | null;
    custom: boolean | null;
  } | null;
  filters: {};
  filterDrawer: {
    open: boolean;
    disabled: boolean;
    hiddenFilter: string | null;
  };
};

const initialState: SurveyDashboardTypes = {
  showSurveyMenu: false,
  survey: {
    planningId: null,
    roundId: null,
    isEngagement: true,
    planningName: '',
    participants: 0,
    recipients: 0,
    participation: 0,
    lastSend: null,
    expiredAt: null,
    history: [],
  },
  segment: {
    type: null,
    value: null,
    name: null,
    includeChildren: false,
    forceCombineView: false,
    hasChildren: false,
  },
  driver: {
    id: null,
    name: null,
    custom: false,
  },
  filters: {
    age: {
      all: true,
      min: 18,
      max: 90,
    },
    teneur: {
      all: true,
      min: 0,
      max: 50,
      unit: 'year',
    },
    sex: {
      all: true,
      value: 'M',
    },
    classe: {
      all: true,
      value: [],
    },
    location: {
      all: true,
      value: [],
    },
  },
  filterDrawer: {
    open: false,
    disabled: false,
    hiddenFilter: SEGMENT_TYPES.AGE,
  },
};

const slice = createSlice({
  name: 'surveyDashboard',
  initialState,
  reducers: {
    showSurveyMenu: (state, action) => {
      state.showSurveyMenu = true;
    },
    hideSurveyMenu: (state, action) => {
      state.showSurveyMenu = false;
    },
    setSurvey: (state, action) => {
      state.survey = {
        ...state.survey,
        ...action.payload,
      };
    },
    setRoundId: (state, action) => {
      state.survey.roundId = action.payload;
    },
    setSegment: (state, action) => {
      state.segment = {
        ...state.segment,
        ...action.payload,
      };
    },
    resetSegment: (state) => {
      state.segment = initialState.segment;
    },
    setDriver: (state, action) => {
      state.driver = {
        ...state.driver,
        ...action.payload,
      };
    },
    resetDriver: (state) => {
      state.driver = initialState.driver;
    },
    openFilterDrawer: (state) => {
      state.filterDrawer.open = true;
    },
    closeFilterDrawer: (state) => {
      state.filterDrawer.open = false;
    },
    disableFilterDrawer: (state) => {
      state.filterDrawer.disabled = true;
    },
    enableFilterDrawer: (state) => {
      state.filterDrawer.disabled = false;
    },
    setHiddenFilter: (state, action) => {
      state.filterDrawer.hiddenFilter = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

// Reducer actions
export const {
  setSurvey,
  setRoundId,
  setSegment,
  resetSegment,
  setDriver,
  resetDriver,
  showSurveyMenu,
  hideSurveyMenu,
  openFilterDrawer,
  closeFilterDrawer,
  setFilters,
  resetFilters,
  disableFilterDrawer,
  enableFilterDrawer,
  setHiddenFilter,
} = slice.actions;

// Reducer
export default slice.reducer;
