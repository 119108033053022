import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

import { useAuthContext } from 'src/auth/hooks';
import { getCompanyLogo, getExternalCompanyLogo } from 'src/helpers/company';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetEmailTemplate(templateType: string, locale: string) {
  const { user } = useAuthContext();
  let companyLogo = `${getCompanyLogo(user?.companyId)}?=${Date.now()}`;

  if (user?.companyExternalLogo) {
    companyLogo = `${getExternalCompanyLogo(user?.companyId)}?=${Date.now()}`;
  }

  const URL = `/emailtemplates?type=${templateType}&locale=${locale}`;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  let emailTemplate: any;
  if (data) {
    emailTemplate = data;
    emailTemplate.email_templates_trans[0].template =
      emailTemplate.email_templates_trans[0].template
        .replace('.png', `.png?rand=${Date.now()}`)
        .replace('%recipient.logo%', companyLogo);
  }

  const memoizedValue = useMemo(
    () => ({
      emailTemplate,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [emailTemplate, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetSubsidiaries() {
  const URL = `/company/subsidiaries`;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      subsidiaries: data,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function updateEmailTemplate(id: number, data: any) {
  const resp = await axiosInstance.patch(`/emailtemplate/${id}`, data);
  return resp.data;
}

export async function updateMailingSettings(data: any) {
  const res = await axiosInstance.patch('/company/updateMailingSettings', data);
  return res.data;
}

export async function updateSubsidiarySettings(data: any) {
  const res = await axiosInstance.patch('/company/updateSubsidiarySettings', data);
  return res.data;
}

export async function uploadSubsidiaryLogo(data: any) {
  const res = await axiosInstance.post('/company/uploadSubsidiaryLogo', data);
  return res.data;
}

export async function resetSubsidiaryLogo(data: any) {
  const res = await axiosInstance.post('/company/resetSubsidiaryLogo', data);
  return res.data;
}
