import { m } from 'framer-motion';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Stack, Switch, Divider, ButtonBase } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';
import { useLocales, useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import { useSettingsContext } from 'src/components/settings';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import MoonSvg from 'src/assets/images/ic_moon.svg';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const router = useRouter();
  const { t, i18n } = useTranslation();

  const { user, logout } = useAuthContext();

  const { allLangs, currentLang } = useLocales();
  const { onChangeLang } = useTranslate();

  const popover = usePopover();
  const langPopover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleChangeLang = useCallback(
    (newLang: string) => {
      onChangeLang(newLang);

      langPopover.onClose();
      window.location.reload();
    },
    [onChangeLang, popover]
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.avatar}
          alt={user?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.name.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Box sx={{ ml: 0.5 }}>
        <Typography variant="subtitle2" noWrap sx={{ mb: -0.2 }}>
          {user?.name}
        </Typography>

        <Typography variant="body2" fontSize={13} sx={{ color: 'text.secondary' }} noWrap>
          {user?.role?.roles_trans?.find((role: any) => role.locale === i18n.language)?.name || ''}
        </Typography>
      </Box>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            mb={1}
          >
            <Stack direction="row" alignItems="center" spacing={1} py={0.8} px={0.7}>
              <ButtonBase
                // onClick={() => onChange(option)}
                disabled
                sx={{
                  // width: 1,
                  height: 18,
                  borderRadius: 1,
                  // border: (theme: any) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
                  ...(settings.themeMode === 'dark' && {
                    bgcolor: 'background.paper',
                    boxShadow: (theme: any) =>
                      `-24px 8px 24px -4px ${alpha(
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[500]
                          : theme.palette.common.black,
                        0.08
                      )}`,
                  }),
                  '& .svg-color': {
                    background: (theme) =>
                      `linear-gradient(135deg, ${theme.palette.grey[500]} 0%, ${theme.palette.grey[600]} 100%)`,
                    ...(settings.themeMode === 'dark' && {
                      background: (theme) =>
                        `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                  },
                }}
              >
                <SvgColor src={MoonSvg} />
              </ButtonBase>

              <Typography variant="body2" fontSize={13} noWrap sx={{ mb: -0.2 }}>
                Dark Mode
              </Typography>
            </Stack>

            <Switch
              size="small"
              checked={settings.themeMode === 'dark'}
              onChange={() =>
                settings.onUpdate('themeMode', settings.themeMode === 'dark' ? 'light' : 'dark')
              }
            />
          </Stack>

          <MenuItem onClick={langPopover.onOpen}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width={1}
              spacing={1}
            >
              <Stack direction="row" alignItems="center" spacing={1.3}>
                <Iconify
                  icon={currentLang.icon}
                  sx={{ borderRadius: 0.9, width: 22, marginRight: '0px !important' }}
                />

                {currentLang.label}
              </Stack>

              <Iconify
                icon="eva:arrow-ios-forward-fill"
                width={16}
                sx={{ color: 'text.secondary', marginRight: '0px !important' }}
              />
            </Stack>
          </MenuItem>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          {t('common.logout')}
        </MenuItem>
      </CustomPopover>

      <CustomPopover
        open={langPopover.open}
        onClose={langPopover.onClose}
        arrow="left-top"
        sx={{ width: 160 }}
      >
        {allLangs
          .filter((option) => option.value !== currentLang.value)
          .map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Iconify icon={option.icon} sx={{ borderRadius: 0.9 }} />

              {option.label}
            </MenuItem>
          ))}
      </CustomPopover>
    </>
  );
}
