import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';
import { IGroup } from 'src/types/group';
import {
  groupCategories,
  groupCustomQuestionsPulse,
  groupQuestionsPulse,
} from 'src/helpers/question';
import { isEmpty } from 'lodash';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetCategories(type: 'standard' | 'custom', surveyTypeId: number) {
  const URL = `/dashboard/questioncategories?type=${type}&surveyTypeId=${surveyTypeId}`;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  let categories: any;

  if (data) categories = groupCategories(data);

  const memoizedValue = useMemo(
    () => ({
      categories,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [categories, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function createOpenQuestion(data: any) {
  const res = await axiosInstance.post('/customopenquestion', data);
  return res.data;
}

export async function createPulseQuestion(data: any) {
  const res = await axiosInstance.post('/custompulsequestion', data);
  return res.data;
}

export async function createCategory(data: any) {
  const res = await axiosInstance.post('/custompulsequestioncategory', data);
  return res.data;
}

export async function updateCategory(id: number, data: any) {
  const res = await axiosInstance.patch(`/custompulsequestioncategory/${id}`, data);
  return res.data;
}

export async function deleteCategory(id: number) {
  const res = await axiosInstance.delete(`/custompulsequestioncategory/${id}`);
  return res.data;
}

export async function beforeDeleteCategory(id: number) {
  const res = await axiosInstance.post(`/custompulsequestioncategory/before-deletion/${id}`);
  return res.data;
}

export async function categoryHasPlannings(id: number) {
  const res = await axiosInstance.post(`/custompulsequestioncategory/has-plannings/${id}`);
  return res.data;
}

export async function createSubCategory(data: any) {
  const res = await axiosInstance.post('/custompulsequestionsubcats', data);
  return res.data;
}

export async function updateSubCategory(id: number, data: any) {
  const res = await axiosInstance.patch(`/custompulsequestionsubcats/${id}`, data);
  return res.data;
}

export async function deleteSubCategory(id: number) {
  const res = await axiosInstance.delete(`/custompulsequestionsubcats/${id}`);
  return res.data;
}

export async function beforeDeleteSubCategory(id: number) {
  const res = await axiosInstance.post(`/custompulsequestionsubcats/before-deletion/${id}`);
  return res.data;
}

export async function subCategoryHasPlannings(id: number) {
  const res = await axiosInstance.post(`/custompulsequestionsubcats/has-plannings/${id}`);
  return res.data;
}
