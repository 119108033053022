import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function container(theme: Theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('xs')]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
          [theme.breakpoints.up('xl')]: {
            // maxWidth: 1400,
          },
        },
        maxWidthXl: {
          [theme.breakpoints.up('xl')]: {
            maxWidth: 1420,
          },
        },
      },
    },
    // "MuiContainer-maxWidthXl": {
    //   styleOverrides: {
    //     "@media (min-width: 1920px)": {
    //       maxWidth: "1920px"
    //     }
    //   }
    // }
  };
}
