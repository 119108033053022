import useSWR from 'swr';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import axiosInstance, { fetcher } from 'src/utils/axios';

import { useAuthContext } from 'src/auth/hooks';

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetEmployees(filters: any = {}) {
  const page = useSelector((state: any) => state.employee.page) || 0;
  const rowsPerPage = useSelector((state: any) => state.employee.rowsPerPage) || 5;
  const groups = useSelector((state: any) => state.employee.filters.groups) || [];
  const search = useSelector((state: any) => state.employee.search) || '';

  const offset = page * rowsPerPage;

  let URL = `/employeedirectory?count=${rowsPerPage}&offset=${offset}&sort=lastName`;
  if (groups.length) URL += `&groups=${groups}`;
  if (search && search.length) URL += `&search=${search}`;
  // if (without_role !== undefined) queryURL += `&withoutRole=${without_role}`;

  if (!isEmpty(filters)) {
    URL += `&${Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join('&')}`;
  }

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      employees: data?.rows || [],
      count: data?.count || 0,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetEmployee(id: string | undefined) {
  const URL = `/employees/${id}`;

  const { data: employee, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      employee,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [employee, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetJobs() {
  const { data, isLoading, error, mutate } = useSWR(`/jobtitles`, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      error,
      mutate,
    }),
    [data, error, isLoading, mutate]
  );

  return memoizedValue;
}

export function useGetManagers() {
  const { user } = useAuthContext();

  const URL = `/employees?isManager=1&companyId=${user?.companyId}`;
  const { data, isLoading, error } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      error,
    }),
    [data, error, isLoading]
  );

  return memoizedValue;
}

// --------------------------------------------------------
export async function getGroupChildren(id: number) {
  let URL = `/groups`;
  if (id) {
    URL += `?parentGroupId=${id}`;
  }

  const data = await fetcher(URL);

  return data;
}

export async function getGroup(id: number) {
  const data = await fetcher(`/groups/${id}`);

  return data;
}

export async function uploadEmployeeAvatar(data: object) {
  const res = await axiosInstance.post('/uploadphoto', data);
  return res.data;
}

export async function addEmployee(data: object) {
  const res = await axiosInstance.post('/employees', data);
  return res.data;
}

export async function updateEmployee(id: number | string | undefined, data: object) {
  const res = await axiosInstance.patch(`/employees/${id}`, data);
  return res.data;
}

export async function importJobs(data: object) {
  const res = await axiosInstance.post('/import/importjobs', data);
  return res.data;
}

export async function importEmployees(data: object) {
  const res = await axiosInstance.post('/import/importemployees', data);
  return res.data;
}

export async function importDeleteEmployees(data: object) {
  const res = await axiosInstance.post('/import/importdeleteemployees', data);
  return res.data;
}

export async function addJob(data: object) {
  const res = await axiosInstance.post('/jobtitle', data);
  return res.data;
}

export async function updateJob(id: number | string | undefined, data: object) {
  const res = await axiosInstance.patch(`/jobtitle/${id}`, data);
  return res.data;
}

export async function deleteJob(id: number | string | undefined) {
  const res = await axiosInstance.delete(`/jobtitle/${id}`);
  return res.data;
}
