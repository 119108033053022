import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

// -------------------------------------------------

const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetElearning(id: number | null, custom: boolean) {
  let URL = null;

  if (id) {
    URL = `/learning?id=${id}&custom=${custom}`;
  }

  const { data: elearning, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      elearning,
      isLoading,
      isEmpty: !isLoading && !elearning?.length,
      error,
      isValidating,
      mutate,
    }),
    [elearning, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function saveElearning(data: object) {
  const res = await axiosInstance.post('/learning', data);
  return res.data;
}

export async function deleteElearning(id: number) {
  const res = await axiosInstance.delete(`/learning/${id}`);
  return res.data;
}
