import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// slices
import commonReducer from './slices/common';
import surveyDashboardReducer from './slices/survey-dashboard';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['surveyDashboard'],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['checkout'],
// };

type AsyncReducers = {
  [key: string]: any;
};

export const rootReducer = (asyncReducers?: AsyncReducers) => (state: any, action: any) => {
  const combinedReducer = combineReducers({
    common: commonReducer,
    surveyDashboard: surveyDashboardReducer,
    // chat: chatReducer,
    // kanban: kanbanReducer,
    // calendar: calendarReducer,
    // product: persistReducer(productPersistConfig, productReducer),
    ...asyncReducers,
  });

  if (action.type === 'DESTROY_SESSION') state = undefined;

  return combinedReducer(state, action);
};
