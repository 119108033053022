import { useSelector } from 'react-redux';

import { Box, CircularProgress } from '@mui/material';

export default function GlobalLoading() {
  const { globalLoading } = useSelector((state: any) => state.common);

  if (!globalLoading) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        backgroundColor: '#ffffffa0',
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
}
